// import React, { useEffect, useState } from 'react';
// import "../list/list.scss";
// import '../UserDetailsViewpg/UserDetails.css';
// import Navbar from '../../components/navbar/Navbar';
// import Sidebar from '../../components/sidebar/Sidebar';
// import axios from 'axios';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { Spinner } from 'react-bootstrap';
// import { useNavigate } from 'react-router-dom';

// const AddNotification = () => {
//   const [loading, setLoading] = useState(false);
//   const navigate = useNavigate();
//   const [data, setData] = useState({
//     title: '',
//     description: '',
//     date:''
//   });
 
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   const handleSubmit = async () => {
//     const token = localStorage.getItem('dashboard_key')
//     setLoading(true);
//     const { title, description ,date} = data;

//     try {
//       const response = await axios.post('https://backend.mysecretlit.com/api/addNotification', {
//         title,
//         description,
//         date
//       },{
//         headers: {
//           Authorization: `Bearer ${token}`
//         },
//       });
//       toast.success('Your notification was successfully added!');
//       setTimeout(() => {
//         navigate('/Dashboard/all-notifications');
//       }, 2000);
//     } catch (error) {
//       toast.error('Error submitting form. Please try again.');
//       console.error('Error submitting form:', error.response ? error.response.data : error.message);
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div className='list'>
//       <Sidebar />
//       <div className="listContainer">
//         <ToastContainer />
//         <Navbar headingmain="Add New Notifications" />
//         <div style={{ width: '97%', margin: 'auto' }}>
         

//           <div className="input-feild-label" style={{ marginTop: '1em', width: '98%' }}>
//             <p className="paragraph-details-user">Add Title</p>
//             <textarea
//               value={data.title}
//               onChange={(e) => setData({ ...data, title: e.target.value })}
//               rows="2"
//               className='inputfeild-userDetails'
//               placeholder='ADD Title'
//             />
//           </div>
//           <div className="input-feild-label" style={{ marginTop: '1em', width: '98%' }}>
//             <p className="paragraph-details-user">Select Date</p>
//             <input
//           className='inputfeild-userDetails'
//           type="date"
//           value={data.date}
//           onChange={(e) => setData({ ...data, date: e.target.value })}
//           placeholder="Expire date"
//           style={{ flex: '1', padding: '8px', marginRight: '10px', fontSize: '14px' }}
//         />
//           </div>

//           <div className="input-feild-label" style={{ marginTop: '1em', width: '98%' }}>
//             <p className="paragraph-details-user">Add Description</p>
//             <textarea
//               value={data.description}
//               onChange={(e) => setData({ ...data, description: e.target.value })}
//               rows="6"
//               className='inputfeild-userDetails'
//               placeholder='ADD Description'
//             />
//           </div>

//           {loading && (
//             <div className="spinner-overlay">
//               <Spinner animation="border" variant="danger" />
//             </div>
//           )}
//         </div>
//         <button
//           className='button-all-css'
//           onClick={handleSubmit}
//           style={{ marginTop: '2em', marginBottom: '2em', width: '98%' }}
//         >
//           Add Notifications
//         </button>
//       </div>
//     </div>
//   );
// };

// export default AddNotification;
import React, { useEffect, useState } from 'react';
import "../list/list.scss";
import '../UserDetailsViewpg/UserDetails.css';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

const AddNotification = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState({
    title: '',
    description: '',
    date: new Date()
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = async () => {
    const token = localStorage.getItem('dashboard_key');
    setLoading(true);
    const { title, description, date } = data;

    try {
      await axios.post('https://backend.mysecretlit.com/api/addNotification', {
        title,
        description,
        date: moment(date).format('YYYY-MM-DD')
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        },
      });
      toast.success('Your notification was successfully added!');
      setTimeout(() => {
        navigate('/Dashboard/all-notifications');
      }, 2000);
    } catch (error) {
      toast.error('Error submitting form. Please try again.');
      console.error('Error submitting form:', error.response ? error.response.data : error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='list'>
      <Sidebar />
      <div className="listContainer">
        <ToastContainer />
        <Navbar headingmain="Add New Notifications" />
        <div style={{ width: '97%', margin: 'auto' }}>
          <div className="input-feild-label" style={{ marginTop: '1em', width: '98%' }}>
            <p className="paragraph-details-user">Add Title</p>
            <textarea
              value={data.title}
              onChange={(e) => setData({ ...data, title: e.target.value })}
              rows="2"
              className='inputfeild-userDetails'
              placeholder='ADD Title'
            />
          </div>

          <div className="input-feild-label" style={{ marginTop: '1em', width: '98%' }}>
            <p className="paragraph-details-user">Select Date</p>
            <DatePicker
             style={{width:'100%'}}
              selected={data.date}
              onChange={(date) => setData({ ...data, date })}
              dateFormat="dd/MM/yyyy"
              className="inputfeild-userDetails"
              placeholderText="Select date"
            />
          </div>

          <div className="input-feild-label" style={{ marginTop: '1em', width: '98%' }}>
            <p className="paragraph-details-user">Add Description</p>
            <textarea
              value={data.description}
              onChange={(e) => setData({ ...data, description: e.target.value })}
              rows="6"
              className='inputfeild-userDetails'
              placeholder='ADD Description'
            />
          </div>

          {loading && (
            <div className="spinner-overlay">
              <Spinner animation="border" variant="danger" />
            </div>
          )}
        </div>
        <button
          className='button-all-css'
          onClick={handleSubmit}
          style={{ marginTop: '2em', marginBottom: '2em', width: '98%' }}
        >
          Add Notifications
        </button>
      </div>
    </div>
  );
};

export default AddNotification;
