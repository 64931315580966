import React, { useState } from 'react'
import "../list/list.scss"
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import AllFAQSQuesTable from './AllFAQSQuesTable';
import { useNavigate } from 'react-router-dom';
const GetAllFAQS = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const navigation = useNavigate()
  return (
    <div className='list'>
        <Sidebar/>
        <div className="listContainer">
        <Navbar onchange={(e) => setSearchQuery(e.target.value)} searchQuery={searchQuery} headingmain="All FAQS QUESTION & ANSWERS" showSearchBar={true}/>
        <div className="add-categroy-name" style={{width:'97%',margin:'auto',marginTop:30,paddingRight:10}}>
            <div className="auidoBook-div-sub-coln"><h2 className='heading-h2-all-main' style={{color:'#ee2d2f',fontSize:22}}>Add New FAQ'S Question & Answers</h2></div>
            <div className="auidoBook-div-sub-coln"><button className="addButton" onClick={()=>navigation('/Dashboard/get-all-faqs/add-faqs-question-and-answer')} style={{width:'13em',background:'#ee2d2f',color:'white'}}>Add New FAQ'S</button></div>
        </div>
        <AllFAQSQuesTable searchQuery={searchQuery}/>
        <div className='all-book-style'>
        </div>
        
      </div>
    </div>
  )
}

export default GetAllFAQS