import React, { useEffect, useState } from 'react';
import "../list/list.scss";
import '../UserDetailsViewpg/UserDetails.css';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const AddFAQSQuestion = () => {
  const [catdata, setCatData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState({
    question: '',
    answer: '',
    faqCategoryId: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://backend.mysecretlit.com/api/getFaqCategories');
        setCatData(response.data.data.reverse());
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    window.scrollTo(0, 0);
  }, []);

  const handleSeriesChange = (e) => {
    setData({ ...data, faqCategoryId: e.target.value });
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem('maintoken');
    setLoading(true);
    const { faqCategoryId, question, answer } = data;

    try {
      const response = await axios.post('https://backend.mysecretlit.com/api/addFaq', {
        faqCategoryId,
        question,
        answer
      },{
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      toast.success('Your FAQ was successfully added!');
      setTimeout(() => {
        navigate('/Dashboard/get-all-faqs');
      }, 2000);
    } catch (error) {
      toast.error('Error submitting form. Please try again.');
      console.error('Error submitting form:', error.response ? error.response.data : error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='list'>
      <Sidebar />
      <div className="listContainer">
        <ToastContainer />
        <Navbar headingmain="Add New FAQ'S Question & Answers" />
        <div style={{ width: '97%', margin: 'auto' }}>
          <div className="input-feild-label" style={{ width: '98%', marginTop: '4em' }}>
            <p className="paragraph-details-user">ADD Question Category</p>
            <select id="cars"
              value={data.faqCategoryId}
              onChange={handleSeriesChange}
              style={{ textTransform: 'capitalize' }}
            >
              <option value=''>ADD Question Category</option>
              {catdata.map(category => (
                <option key={category._id} value={category._id}>{category.name}</option>
              ))}
            </select>
          </div>

          <div className="input-feild-label" style={{ marginTop: '1em', width: '98%' }}>
            <p className="paragraph-details-user">ADD Question</p>
            <textarea
              value={data.question}
              onChange={(e) => setData({ ...data, question: e.target.value })}
              rows="2"
              className='inputfeild-userDetails'
              placeholder='ADD Question'
            />
          </div>

          <div className="input-feild-label" style={{ marginTop: '1em', width: '98%' }}>
            <p className="paragraph-details-user">ADD Answer</p>
            <textarea
              value={data.answer}
              onChange={(e) => setData({ ...data, answer: e.target.value })}
              rows="6"
              className='inputfeild-userDetails'
              placeholder='ADD Answer'
            />
          </div>

          {loading && (
            <div className="spinner-overlay">
              <Spinner animation="border" variant="danger" />
            </div>
          )}
        </div>
        <button
          className='button-all-css'
          onClick={handleSubmit}
          style={{ marginTop: '2em', marginBottom: '2em', width: '98%' }}
        >
          Add FAQ
        </button>
      </div>
    </div>
  );
};

export default AddFAQSQuestion;
