

import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { BookColmData } from "../../datatablesource";
import '../UserDetailsViewpg/UserDetails.css';
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import axios from "axios";

const AddHomeCatgoryBanner = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { dataa } = location.state || {}; // Extract data from location.state
  const [data, setData] = useState([]); // State to hold book data
  // Function to format and set the data
  const formatAndSetData = (incomingData) => {
    const formattedData = incomingData.map((item) => ({
      ...item,
      id: item._id, // Ensure each item has a unique `id`
      homeBanner: item.categoryBanner || false, // Initialize banner status
    }));
    setData(formattedData);
  };

  // UseEffect to set the initial data from props when component mounts
  useEffect(() => {
    if (dataa) {
      formatAndSetData(dataa);
    }
  }, [dataa]);

  const handleBannerAction = async (bookId, currentBannerStatus) => {
    // Temporarily update the state to reflect the changes in the UI
    const updatedData = data.map((book) =>
      book.id === bookId ? { ...book, homeBanner: !currentBannerStatus } : book
    );
    
    // Update the state
    setData(updatedData);
  
    // Determine the new banner status
    const bannerStatus = !currentBannerStatus ? 'true' : 'false';
  
    try {
      // Retrieve the token from localStorage
      const token = localStorage.getItem('maintoken');
      
      // Set up the headers with the token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
  
      // Send the API request to update the banner status
      const response = await axios.post('https://backend.mysecretlit.com/api/CategoryBannerBook', {
        status: bannerStatus,
        bookId: bookId,
      }, config);
  
      // Check if the response was successful
      if (response.data && response.data.success) {
        toast.success(`Banner ${bannerStatus === 'true' ? 'added' : 'removed'} successfully`);
      } else {
        toast.success(`Banner ${bannerStatus === 'true' ? 'added' : 'removed'} successfully`);
        // Revert the state change if the API call was unsuccessful
        // setData(data);
      }
    } catch (error) {
      console.error('Error updating banner status:', error);
      toast.error('Failed to update banner status');
      // Revert the state change if an error occurred
      // setData(data);
    }
  };
  

  // Sorting the data based on banner status (true banners at the top)
  const sortedData = [...data].sort((a, b) => {
    return a.homeBanner === b.homeBanner ? 0 : a.homeBanner ? -1 : 1;
  });

  // Column definition for DataGrid with action buttons
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        const isBannerAdded = !!params.row.homeBanner;
        return (
          <div className="cellAction">
            <div
              onClick={() => handleBannerAction(params.row.id, isBannerAdded)}
              className={`viewButton ${isBannerAdded ? 'bannerAdded' : ''}`}
            >
              {isBannerAdded ? 'Delete Banner Category' : 'Add Banner Category'}
            </div>
          </div>
        );
      },
    },
  ];

  // Render the DataGrid component with books data and action columns
  return (
    <div className='list'>
      <Sidebar />
      <div className="listContainer">
        <Navbar headingmain="All Home Page Categories Render Book" showSearchBar={false} />
        <div className="datatable" style={{ paddingTop: 40 }}>
          <ToastContainer />
          {data.length === 0 ? (
            <div className="loadingSpinner">
              NO Books On This Category Plz Add the Book
            </div>
          ) : (
            <DataGrid
              className="datagrid"
              rows={sortedData}
              columns={BookColmData.concat(actionColumn)}
              pageSize={100}
              rowsPerPageOptions={[30]}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddHomeCatgoryBanner;
