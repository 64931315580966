// import React, { useEffect, useState } from 'react';
// import "../list/list.scss";
// import '../UserDetailsViewpg/UserDetails.css';
// import Navbar from '../../components/navbar/Navbar';
// import Sidebar from '../../components/sidebar/Sidebar';
// import axios from 'axios';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { Spinner } from 'react-bootstrap';
// import { useLocation, useNavigate } from 'react-router-dom';

// const UpdateFAQSQuestion = () => {
//   const location = useLocation();
//   const { dataa } = location.state || {}; // Add default empty object
//   const [catdata, setCatData] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const navigate = useNavigate();
//   console.log('dataa',dataa)
//   const [data, setData] = useState({
//     question: dataa?.question || '',
//     answer: dataa?.answer || '',
//     questionId: dataa?._id   || '',
//   });

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get('https://backend.mysecretlit.com/api/getFaqCategories');
//         setCatData(response.data.data);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   const handleSeriesChange = (e) => {
//     setData({ ...data, questionId: e.target.value });
//   };

//   const handleSubmit = async () => {
//     setLoading(true);
//     const { questionId, question, answer } = data;

//     try {
//       const response = await axios.post('https://backend.mysecretlit.com/api/updateFaqQuestion', {
//         questionId,
//         question,
//         answer
//       });
//       toast.success('Your FAQ was successfully updated!');
//       setTimeout(() => {
//         navigate('/Dashboard/get-all-faqs');
//       }, 2000);
//     } catch (error) {
//       toast.error('Error submitting form. Please try again.');
//       console.error('Error submitting form:', error.response ? error.response.data : error.message);
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div className='list'>
//       <Sidebar />
//       <div className="listContainer">
//         <ToastContainer />
//         <Navbar headingmain="Update FAQ'S Question & Answers" />
//         <div style={{ width: '97%', margin: 'auto' }}>
//           <div className="input-feild-label" style={{ width: '98%', marginTop: '4em' }}>
//             <p className="paragraph-details-user">Question Category</p>
//             <select id="cars"
//               value={data.questionId}
//               onChange={handleSeriesChange}
//               style={{ textTransform: 'capitalize' }}
//             >
//               <option value=''>Select Question Category</option>
//               {catdata.map(category => (
//                 <option key={category._id} value={category._id}>{category.name}</option>
//               ))}
//             </select>
//           </div>

//           <div className="input-feild-label" style={{ marginTop: '1em', width: '98%' }}>
//             <p className="paragraph-details-user">Question</p>
//             <textarea
//               value={data.question}
//               onChange={(e) => setData({ ...data, question: e.target.value })}
//               rows="2"
//               className='inputfeild-userDetails'
//               placeholder='Enter Question'
//             />
//           </div>

//           <div className="input-feild-label" style={{ marginTop: '1em', width: '98%' }}>
//             <p className="paragraph-details-user">Answer</p>
//             <textarea
//               value={data.answer}
//               onChange={(e) => setData({ ...data, answer: e.target.value })}
//               rows="6"
//               className='inputfeild-userDetails'
//               placeholder='Enter Answer'
//             />
//           </div>

//           {loading && (
//             <div className="spinner-overlay">
//               <Spinner animation="border" variant="danger" />
//             </div>
//           )}
//         </div>
//         <button
//           className='button-all-css'
//           onClick={handleSubmit}
//           style={{ marginTop: '2em', marginBottom: '2em', width: '98%' }}
//         >
//           Update FAQ
//         </button>
//       </div>
//     </div>
//   );
// };

// export default UpdateFAQSQuestion;
import React, { useEffect, useState } from 'react';
import "../list/list.scss";
import '../UserDetailsViewpg/UserDetails.css';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Spinner } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

const UpdateFAQSQuestion = () => {
  const location = useLocation();
  const { dataa } = location.state || {}; // Add default empty object
  const [catdata, setCatData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  console.log('dataa', dataa);
  const [data, setData] = useState({
    question: dataa?.question || '',
    answer: dataa?.answer || '',
    categoryName: dataa?.categoryName || '', // Use categoryName for pre-selection
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://backend.mysecretlit.com/api/getFaqCategories');
        setCatData(response.data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleCategoryChange = (e) => {
    setData({ ...data, categoryName: e.target.value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    const { categoryName, question, answer } = data;

    try {
      const response = await axios.post('https://backend.mysecretlit.com/api/updateFaqQuestion', {
        questionId:dataa._id, // Use categoryName in the payload
        question,
        answer
      });
      toast.success('Your FAQ was successfully updated!');
      setTimeout(() => {
        navigate('/Dashboard/get-all-faqs');
      }, 2000);
    } catch (error) {
      toast.error('Error submitting form. Please try again.');
      console.error('Error submitting form:', error.response ? error.response.data : error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='list'>
      <Sidebar />
      <div className="listContainer">
        <ToastContainer />
        <Navbar headingmain="Update FAQ'S Question & Answers" />
        <div style={{ width: '97%', margin: 'auto' }}>
          <div className="input-feild-label" style={{ width: '98%', marginTop: '4em' }}>
            <p className="paragraph-details-user">Question Category</p>
            <select id="cars"
              value={data.categoryName}
              onChange={handleCategoryChange}
              style={{ textTransform: 'capitalize' }}
            >
              <option value=''>Select Question Category</option>
              {catdata.map(category => (
                <option key={category._id} value={category.name}>{category.name}</option> // Use category.name as the value
              ))}
            </select>
          </div>

          <div className="input-feild-label" style={{ marginTop: '1em', width: '98%' }}>
            <p className="paragraph-details-user">Question</p>
            <textarea
              value={data.question}
              onChange={(e) => setData({ ...data, question: e.target.value })}
              rows="2"
              className='inputfeild-userDetails'
              placeholder='Enter Question'
            />
          </div>

          <div className="input-feild-label" style={{ marginTop: '1em', width: '98%' }}>
            <p className="paragraph-details-user">Answer</p>
            <textarea
              value={data.answer}
              onChange={(e) => setData({ ...data, answer: e.target.value })}
              rows="6"
              className='inputfeild-userDetails'
              placeholder='Enter Answer'
            />
          </div>

          {loading && (
            <div className="spinner-overlay">
              <Spinner animation="border" variant="danger" />
            </div>
          )}
        </div>
        <button
          className='button-all-css'
          onClick={handleSubmit}
          style={{ marginTop: '2em', marginBottom: '2em', width: '98%' }}
        >
          Update FAQ
        </button>
      </div>
    </div>
  );
};

export default UpdateFAQSQuestion;
