import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress"; // Import CircularProgress component from MUI
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { allCategory } from "../../datatablesource";

const AllFAQSTable = ({ searchQuery }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // State for loading indicator
  const [editId, setEditId] = useState(null); // State for tracking the item being edited
  const [editValue, setEditValue] = useState(''); // State for tracking the edited value

  const handleDelete = async (id) => {
    const token = localStorage.getItem('maintoken');
    setData(data.filter((item) => item.id !== id)); // Update local state
    try {
      await axios.delete(`https://backend.mysecretlit.com/api/deleteFaqCategory/${id}`,{
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      toast.success('This category is successfully deleted!'); // Show success toast
    } catch (error) {
      console.error('Error making API call:', error);
      if (error.response) {
        console.log('Error Response Data:', error.response.data);
        toast.error(error.response.data.message || 'Error deleting category. Please try again.');
      } else if (error.request) {
        console.log('Error Request Data:', error.request);
        toast.error('No response from server. Please try again.');
      } else {
        console.log('Error Message:', error.message);
        toast.error('Error deleting category. Please try again.');
      }
    }
  };

  const handleEdit = (id, currentName) => {
    setEditId(id);
    setEditValue(currentName);
  };

  const handleUpdate = async () => {
    try {
      await axios.put(`https://new-app-testing-2d30280db142.herokuapp.com/api/updateFaqQuestion${editId}`, {
        name: editValue
      });
      toast.success('This category is successfully updated!');
      setData(data.map(item => item.id === editId ? { ...item, name: editValue } : item)); // Update the local state
      setEditId(null);
      setEditValue('');
    } catch (error) {
      console.error('Error making API call:', error);
      if (error.response) {
        console.log('Error Response Data:', error.response.data);
        toast.error(error.response.data.message || 'Error updating category. Please try again.');
      } else if (error.request) {
        console.log('Error Request Data:', error.request);
        toast.error('No response from server. Please try again.');
      } else {
        console.log('Error Message:', error.message);
        toast.error('Error updating category. Please try again.');
      }
    }
  };

  const fetchData = async () => {
    setIsLoading(true); // Start loading indicator
    try {
      const response = await axios.get('https://backend.mysecretlit.com/api/getFaqCategories');
      const formattedData = response.data.data.reverse().map((item) => ({
        ...item,
        id: item._id // Use '_id' or another unique property as 'id'
      }));
      setData(formattedData);
      
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false); // Stop loading indicator
    }
  };

  const filteredData = data.filter(users => 
    users.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, []);

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 350,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            {editId === params.row.id ? (
              <>
                <input
                  type="text"
                  value={editValue}
                  onChange={(e) => setEditValue(e.target.value)}
                />
                <button className="viewButton" onClick={handleUpdate}>Update</button>
              </>
            ) : (
              <>
                <button
                  className="viewButton"
                  onClick={() => handleEdit(params.row.id, params.row.name)}
                >
                  Edit
                </button>
                <button
                  className="deleteButton"
                  onClick={() => handleDelete(params.row.id)}
                >
                  Delete
                </button>
              </>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className="datatable" style={{ paddingTop: 0 }}>
      <div className="datatableTitle">All FAQ'S Categories Data</div>
      <ToastContainer />
      {isLoading ? (
        <div className="loadingSpinner">
          <CircularProgress color="error" />
        </div>
      ) : (
        data?.length > 0 ? (
          <DataGrid
            className="datagrid"
            rows={filteredData}
            columns={allCategory?.concat(actionColumn)}
            pageSize={15}
            rowsPerPageOptions={[9]}
            checkboxSelection
          />
        ) : (
          <p>No data available</p> // Handle empty state if necessary
        )
      )}
    </div>
  );
};

export default AllFAQSTable;
