import React, { useState } from 'react'
import "../list/list.scss"
import '../UserDetailsViewpg/UserDetails.css'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AllFAQSTable from './AllFAQSTable'
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
const AddFAQsCategory = () => {
  const [name,setname] = useState('')
  
  const [searchQuery, setSearchQuery] = useState('');

const handleSubmit = async () => {
  const token = localStorage.getItem('maintoken');

  try {
    const res = await axios.post('https://backend.mysecretlit.com/api/addFaqCategory',{name:name,type:"test"},{
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    toast.success('This FAQS category is successfully added!');
    window.location.reload()
    setname('');

  } catch (error) {
    console.error('Error making API call:', error);
    if (error.response) {
      console.log('Error Response Data:', error.response.data);
      toast.error(error.response.data.message || 'Error adding category. Please try again.');
    } else if (error.request) {
      console.log('Error Request Data:', error.request);
      toast.error('No response from server. Please try again.');
    } else {
      console.log('Error Message:', error.message);
      toast.error('Error adding category. Please try again.');
    }
  } 
};

  return (
    <div className='list'>
         <Sidebar/>
       <div className="listContainer">
           
       <Navbar headingmain="Add FAQ'S Category" onchange={(e) => setSearchQuery(e.target.value)} searchQuery={searchQuery} showSearchBar={true}/>
       <div className='all-book-style'>
        <p className="paragraph-details-user">Add FAQ'S Categroy Name :</p>
        <div className="add-categroy-name">
       <div style={{width:'65%'}} >
    
       <input onChange={(e)=>setname(e.target.value)} value={name}  className='inputfeild-userDetails' type="text" placeholder='Plz add new faqs category name' />    
       </div>
            <div style={{width:'30%'}}>
            <button className='button-all-css' onClick={handleSubmit} >add category</button>
            </div>
            <ToastContainer />
        </div>
        </div>
        <AllFAQSTable searchQuery={searchQuery}/>
        
       </div>
    </div>
  )
}

export default AddFAQsCategory