
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import Checkbox from "@mui/material/Checkbox";
import { allCategory, allSeriesBookdata } from "../../datatablesource"; // Assuming you have imported `allCategory` from your source file
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, MenuItem, Select, InputLabel, FormControl } from "@mui/material";
import { useNavigate } from "react-router-dom";

const AllSeriesCreateTable = ({ searchQuery }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem('maintoken');
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [newCategoryName, setNewCategoryName] = useState("");
 const navigation = useNavigate()
 console.log('vselectedCategoryselectedCategory',selectedCategory)
 const handleDelete = async (id) => {
  try {
    await axios.delete('https://backend.mysecretlit.com/api/admin/deleteSeries', {
      headers: {
        Authorization: `Bearer ${token}`,  // Ensure token is correct
      },
      data: { seriesId: id?._id },  // DELETE requests require payload in 'data'
    });
    toast.success('Category successfully deleted and replaced!');
    window.location.reload();
  } catch (error) {
    console.error('Error making API call:', error);

    if (error.response) {
      toast.error(error.response.data.message || 'Error deleting category. Please try again.');
    } else if (error.request) {
      toast.error('No response from server. Please try again.');
    } else {
      toast.error('Error deleting category. Please try again.');
    }
  }
};
 

  const handleUpdateCategory = async () => {
    try {
      await axios.patch(
        `https://backend.mysecretlit.com/api/admin/updateseries/${selectedCategory._id}`,
        {
          name: newCategoryName, 
          seriesId:selectedCategory?._id
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Category updated successfully!");
      fetchData();
      setEditModalOpen(false);
    } catch (error) {
      console.error('Error updating category:', error);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('https://backend.mysecretlit.com/api/user/getAllSeries', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const formattedData = response.data.data.reverse().map((item) => ({
        ...item,
        id: item._id,
      }));
      setData(formattedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const filteredData = data.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    fetchData();
  }, []);

  const handleEdit = (category) => {
    console.log('category',category)
    setSelectedCategory(category);
    setNewCategoryName(category.name);
    setEditModalOpen(true);
  };
  const HandleView = (book)=>{
    console.log('bokodasd',book.books
)
navigation('/Dashboard/create-a-series-books/view-all-series-books',{ state: { data: book } })
  }

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 250,
      renderCell: (params) => (
        <div className="cellAction">
          <div className="deleteButton" onClick={() => handleDelete(params.row)}>
            Delete
          </div>
          <div className="viewButton" onClick={() => handleEdit(params.row)}>
            Edit
          </div>
          <div className="viewButton" onClick={() => HandleView(params.row)}>
            View
          </div>
        </div>
      ),
    },
    // {
    //   field: "select",
    //   headerName: "Home Genres Category",
    //   width: 200,
    //   renderCell: renderCell,
    // },
  ];

  return (
    <div className="datatable" style={{ paddingTop: 0 }}>
      <div className="datatableTitle">All Series Book Data</div>
      <ToastContainer />
      {isLoading ? (
        <div className="loadingSpinner">
          <CircularProgress color="error" />
        </div>
      ) : (
        data?.length > 0 ? (
          <DataGrid
            className="datagrid"
            rows={filteredData}
            columns={allSeriesBookdata?.concat(actionColumn)}
            pageSize={25}
            rowsPerPageOptions={[9]}
          />
        ) : (
          <p>No data available</p>
        )
      )}
      
      {/* Edit Modal */}
      <Dialog
        open={editModalOpen} onClose={() => setEditModalOpen(false)}
        aria-labelledby="edit-dialog-title"
        aria-describedby="edit-dialog-description"
      >
        <DialogTitle id="edit-dialog-title">Edit Your Category Name</DialogTitle>
        <DialogContent>
          <TextField
            label="Category Name"
            value={newCategoryName}
            onChange={(e) => setNewCategoryName(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdateCategory}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>  
    </div>
  );
};

export default AllSeriesCreateTable;
