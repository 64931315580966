import React from 'react'
import Navbar from '../../components/navbar/Navbar'

import Sidebar from '../../components/sidebar/Sidebar'
import { useLocation } from 'react-router-dom'
import moment from 'moment-timezone'
const ContactDetailspg = () => {
    const location = useLocation();
    const { data } = location.state;
    const originalDate = data?.createdAt;
    const formattedDate = moment.tz(originalDate, 'America/New_York').format('MMMM Do YYYY, h:mm:ss a'); // Change the timezone as needed


  return (
    <div className='list'>
     <Sidebar/>
        <div className="listContainer" >
            
        <Navbar headingmain='Complte User Details' />
        <div className='all-book-style'>  
        <div style={{marginTop:'10px'}}>
          <div className="profileImg">
            <img style={{width:'10em',height:'10em',borderRadius:'50%'}} src={data?.img || 'https://images.theconversation.com/files/45159/original/rptgtpxd-1396254731.jpg?ixlib=rb-4.1.0&q=45&auto=format&w=1356&h=668&fit=crop'} alt="" srcset="" />
            <div className="userData-details">
              <div className="name-tag">
                <label  >Name:</label>
                <p>{data.name} <span style={{textTransform:"capitalize"}}>{data.lastname}</span></p>
              </div>
              <div className="name-tag">
                <label  >Email:</label>
                <p>{data.email}</p>
              </div>
              
            </div>
          </div>
        </div>
       
        <div className="input-feild-label" style={{marginTop:'1em'}}>
            <p className="paragraph-details-user"> Name</p>
            <input value={data?.name} className='inputfeild-userDetails' placeholder='Email Address'/>
            </div>
            <div className="input-feild-label" style={{marginTop:'1em'}}>
            <p className="paragraph-details-user"> Email Address</p>
            <input value={data?.email} className='inputfeild-userDetails' placeholder='Email Address'/>
            </div>
        <div className="input-feild-label" style={{marginTop:'1em'}}>
            <p className="paragraph-details-user"> Message send Date</p>
            <input value={formattedDate} className='inputfeild-userDetails' placeholder='Email Address'/>
            </div>
            
            <div className="input-feild-label" style={{marginTop:'1em'}}>
            <p className="paragraph-details-user"> User Messages</p>
            <textarea value={data?.message} rows="4" className='inputfeild-userDetails' placeholder='User Messages'/>
            </div>
        </div>
        
        
      </div>
    </div>
  )
}

export default ContactDetailspg