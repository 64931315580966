
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import { allCategory } from "../../datatablesource"; // Assuming you have imported `allCategory` from your source file
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, MenuItem, Select, InputLabel, FormControl } from "@mui/material";

const AllCategoryTable = ({ searchQuery }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem('maintoken');
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [replacementCategory, setReplacementCategory] = useState("");

  const handleDeleteOpen = (category) => {
    setCategoryToDelete(category);
    setDeleteModalOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteModalOpen(false);
    setReplacementCategory("");
  };

  const handleDelete = async () => {
    if (!replacementCategory) {
      toast.error("Please select a replacement category.");
      return;
    }

    const payload = {
    
      newCategory: replacementCategory
    };

    try {
      await axios.delete(`https://backend.mysecretlit.com/api/book/deletecategory/${categoryToDelete.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: payload,
      });

      setData(data.filter((item) => item.id !== categoryToDelete.id));
      toast.success('Category successfully deleted and replaced!');
      handleDeleteClose();
    } catch (error) {
      console.error('Error making API call:', error);
      if (error.response) {
        toast.error(error.response.data.message || 'Error deleting category. Please try again.');
      } else if (error.request) {
        toast.error('No response from server. Please try again.');
      } else {
        toast.error('Error deleting category. Please try again.');
      }
    }
  };


  const handleUpdateCategory = async () => {
    try {
      await axios.patch(
        `https://backend.mysecretlit.com/api/book/updateCategory/${selectedCategory.id}`,
        { name: newCategoryName, type: "audioBook" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Category updated successfully!");
      fetchData();
      setEditModalOpen(false);
    } catch (error) {
      console.error('Error updating category:', error);
    }
  };


  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('https://backend.mysecretlit.com/api/getAudioCategories');
      const formattedData = response.data.data.reverse().map((item) => ({
        ...item,
        id: item._id,
      }));
      setData(formattedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const filteredData = data.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    fetchData();
 
  }, []);

  
  const handleEdit = (category) => {
    setSelectedCategory(category);
    setNewCategoryName(category.name);
    setEditModalOpen(true);
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 250,
      renderCell: (params) => (
        <div className="cellAction">
          <div className="deleteButton" onClick={() => handleDeleteOpen(params.row)}>
            Delete
          </div>
          <div className="viewButton" onClick={() => handleEdit(params.row)}>
            Edit
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="datatable" style={{ paddingTop: 0 }}>
      <div className="datatableTitle">All Categories Data</div>
      <ToastContainer />
      {isLoading ? (
        <div className="loadingSpinner">
          <CircularProgress color="error" />
        </div>
      ) : (
        data?.length > 0 ? (
          <DataGrid
            className="datagrid"
            rows={filteredData}
            columns={allCategory?.concat(actionColumn)}
            pageSize={25}
            rowsPerPageOptions={[9]}
          />
        ) : (
          <p>No data available</p>
        )
      )}
      
      {/* Edit Modal */}
      <Dialog
        open={editModalOpen} onClose={() => setEditModalOpen(false)}
        aria-labelledby="edit-dialog-title"
        aria-describedby="edit-dialog-description"
      >
        <DialogTitle id="edit-dialog-title">Edit Your Category Name</DialogTitle>
        <DialogContent>
          <TextField
            label="Category Name"
            value={newCategoryName}
            onChange={(e) => setNewCategoryName(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdateCategory}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteModalOpen} onClose={handleDeleteClose}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Delete Category</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this category?</p>
          <FormControl fullWidth>
            <InputLabel id="replacement-category-label">Select Replacement Category</InputLabel>
            <Select
              labelId="replacement-category-label"
              value={replacementCategory}
              onChange={(e) => setReplacementCategory(e.target.value)}
            >
              {data?.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Confirm Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AllCategoryTable;
