
// import { DataGrid } from "@mui/x-data-grid";
// import { useLocation, useNavigate } from "react-router-dom";
// import { useEffect, useState } from "react";
// import { BookColmData } from "../../datatablesource";
// import axios from "axios";
// import { ToastContainer, toast } from "react-toastify";
// import { CircularProgress } from "@mui/material";
// import Sidebar from "../../components/sidebar/Sidebar";
// import Navbar from "../../components/navbar/Navbar";
// import { Button } from "react-bootstrap";

// const ViewAllSeriesBooks = () => {
//   const location = useLocation();
//   const { data } = location.state; // Assuming data.books is an array of books
//   const [dataa, setData] = useState([]);
//   const navigate = useNavigate();
//   const [isLoading, setIsLoading] = useState(false);
//   const token = localStorage.getItem("maintoken");
//   useEffect(() => {
//     if (data && data.books) {
//       const formattedData = data.books.map((item) => ({
//         ...item,
//         id: item._id, // Assign _id as id for DataGrid row
//       }));
//       setData(formattedData); // Set the formatted data
//     }
//   }, [data]);

//   // Delete book handler
 


//   return (
//     <div className='list'>
//       <Sidebar />
//       <div className="listContainer">
//         <div style={{position:'relative',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
//         <Navbar 
//           headingmain={data?.name}
       
//           showSearchBar={false}
//         />
//         <button style={{width: '10em', background: 'rgb(238, 45, 47)', borderColor: 'rgb(238, 45, 47)', position: 'absolute', right: '42px',top: '23px'}} className="btn btn-primary">Add New Book</button>
//         </div>
        
//         <div className='all-book-style'>
//     <div className="datatable">
//       <ToastContainer />
//       {isLoading ? (
//         <div className="loadingSpinner">
//           <CircularProgress color="error" />
//         </div>
//       ) : (
//         <DataGrid
//           className="datagrid"
//           rows={dataa} // Use the formatted data here
//           columns={BookColmData}
//           pageSize={100}
//           rowsPerPageOptions={[120]}
         
//         />
//       )}
//     </div>
//     </div>
//     </div>
//     </div>
//   );
// };

// export default ViewAllSeriesBooks;
import { DataGrid } from "@mui/x-data-grid";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { BookColmData } from "../../datatablesource";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { CircularProgress, Box, Typography, TextField, Avatar, Modal } from "@mui/material";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Button } from "react-bootstrap";
import Autocomplete from '@mui/material/Autocomplete';

const ViewAllSeriesBooks = () => {
  const location = useLocation();
  const { data } = location.state; // Assuming data.books is an array of books
  console.log('dasdasd',data)
  const [dataa, setData] = useState([]);
  const [bookData, setBookData] = useState([]); // All fetched series books
  const [selectedBooks, setSelectedBooks] = useState([]); // Selected books by user
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const token = localStorage.getItem("maintoken");

  const navigate = useNavigate();

  // Fetch series books
  const fetchBooks = async () => {
    try {
      const response = await axios.get('https://backend.mysecretlit.com/api/book/getBooks?type=book');
      const seriesBooks = response.data.data
        .filter(book => book.series) // Only books with series = true
        .reverse(); // Reverse if needed
      setBookData(seriesBooks);
    } catch (error) {
      console.error('Error fetching books:', error);
      toast.error('Failed to fetch books.');
    }
  };

  // Update formatted data for DataGrid
  useEffect(() => {
    if (data && data.books) {
      const formattedData = data.books.map((item) => ({
        ...item,
        id: item._id, // Assign _id as id for DataGrid row
      }));
      setData(formattedData); // Set the formatted data
      // Pre-select already included books
      setSelectedBooks(data.books.map((book) => book._id));
    }
  }, [data]);

  // Open/Close Modal
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  // Handle book selection and deselection
  const handleBookSelect = (event, value) => {
    const selectedIds = value.map((book) => book._id); // Get selected book IDs
    setSelectedBooks(selectedIds); // Update state
  };
  const handleSubmit = async () => {
    try {
      await axios.patch(
        `https://backend.mysecretlit.com/api/admin/updateseries/${data._id}`,
        {
          name: data.name, 
          books: selectedBooks,
          seriesId:data?._id
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Category updated successfully!");
      setIsModalOpen(false)
      navigate('/Dashboard/create-a-series-books')
    } catch (error) {
      console.error('Error updating category:', error);
    }
  };

  // Fetch books when component mounts
  useEffect(() => {
    fetchBooks();
  }, []);

  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <div style={{ position: 'relative', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Navbar headingmain={data?.name} showSearchBar={false} />
          <Button
            style={{ width: '10em', background: 'rgb(238, 45, 47)', borderColor: 'rgb(238, 45, 47)', position: 'absolute', right: '42px', top: '23px' }}
            onClick={handleOpenModal}
          >
            Add New Book
          </Button>
        </div>

        <div className="all-book-style">
          <div className="datatable">
            <ToastContainer />
            {isLoading ? (
              <div className="loadingSpinner">
                <CircularProgress color="error" />
              </div>
            ) : (
              <DataGrid
                className="datagrid"
                rows={dataa}
                columns={BookColmData}
                pageSize={100}
                rowsPerPageOptions={[120]}
              />
            )}
          </div>
        </div>

        {/* Modal for adding books */}
        <Modal open={isModalOpen} onClose={handleCloseModal}>
          <Box sx={{ width: 1200, margin: 'auto', padding: 4, backgroundColor: 'white', borderRadius: 2, marginTop: '10%' }}>
            <Typography variant="h6" marginBottom={2}>Select Books for Series</Typography>
            <Autocomplete
              multiple
              options={bookData}
              getOptionLabel={(option) => option.title}
              value={bookData.filter((book) => selectedBooks.includes(book._id))} // Pre-select books
              onChange={handleBookSelect} // Handle selection changes
              sx={{ width: '100%' }}
              renderInput={(params) => (
                <TextField {...params} label="Select Books for Series" variant="outlined" />
              )}
              renderOption={(props, option) => (
                <Box component="li" {...props} display="flex" alignItems="center">
                  <Avatar src={option.coverImage} alt={option.title} sx={{ marginRight: 2 }} />
                  <Box>
                    <Typography variant="body1" fontWeight="bold">{option.title}</Typography>
                    <Typography variant="body2" color="textSecondary">{option.authorName}</Typography>
                  </Box>
                </Box>
              )}
            />
            <Button variant="primary" onClick={handleSubmit} style={{ marginTop: '1em' }}>
              Update Series
            </Button>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default ViewAllSeriesBooks;
