
import React, { useState } from 'react';
import "../list/list.scss";
import '../UserDetailsViewpg/UserDetails.css';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const AddPaymentPlan = () => {
  const [data, setData] = useState({
    whiteDescription: '',
    cutPrice: '',
    actualPrice: '',
    paymentPlan: '',
    bookDownload: '',
    audioBookDownload: '',
    duration: ''
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  
  const payload = {
    name: data?.paymentPlan,
    price: data?.actualPrice,
    description: data?.whiteDescription,
    cutPrice: data?.cutPrice,
    audioBookLimit: data?.audioBookDownload,
    bookLimit: data?.bookDownload,
    durationInDays: data?.duration, // Send the calculated duration in days here
    currency: "$",
    priceInNumbers:data?.actualPrice
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem('maintoken'); // Retrieve token from local storage
    setLoading(true);
    try {
      const response = await axios.post('https://backend.mysecretlit.com/api/addPlan', payload,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Response:', response.data);
      toast.success('Payment plan added successfully');
      setTimeout(() => {
        navigate('/Dashboard/payment-plans');
      }, 2000);
    } catch (error) {
      console.error('Error adding payment plan:', error);
      toast.error('Failed to add payment plan. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Function to get the duration in days
  const getPaymentPlanDuration = (paymentPlan) => {
    const plans = {
      '1 Month free (with promo code)': 30,
      '1 Month': 30,
      '3 Month': 30 * 3,  // 90 days
      '6 Month': 30 * 6,  // 180 days
      '1 Year': 30 * 12   // 360 days
    };
    return plans[paymentPlan] || 0;
  };

  const handlePaymentPlanChange = (e) => {
    const selectedPlan = e.target.value;
    setData({
      ...data,
      paymentPlan: selectedPlan,
      duration: getPaymentPlanDuration(selectedPlan) // Set the duration in days
    });
  };

  const paymentPlans = [
    { id: 1, paymentPlan: '1 Month free (with promo code)' },
    { id: 2, paymentPlan: '1 Month' },
    { id: 3, paymentPlan: '3 Month' },
    { id: 4, paymentPlan: '6 Month' },
    { id: 5, paymentPlan: '1 Year' }
  ];

  return (
    <div className='list'>
      <Sidebar />
      <div className="listContainer">
        <Navbar headingmain='Add New Payment Plan' />
        <div className='all-book-style'>
          {loading && (
            <div className="spinner-overlay">
              <Spinner animation="border" variant="danger" />
            </div>
          )}
          <div className="row-userDetails" style={{ width: '98%' }}>
            <div className="input-feild-label">
              <p className="paragraph-details-user"> Select a plan</p>
              <select id="cars"
                value={data.paymentPlan}
                onChange={handlePaymentPlanChange}
                style={{ textTransform: 'capitalize' }}
              >
                <option value=''>Select a payment plan</option>
                {paymentPlans.map(plan => (
                  <option key={plan.id} value={plan.paymentPlan}>{plan.paymentPlan}</option>
                ))}
              </select>
            </div>

            <div className="input-feild-label">
              <p className="paragraph-details-user"> Description</p>
              <input
                value={data.whiteDescription}
                onChange={(e) => setData({ ...data, whiteDescription: e.target.value })}
                className='inputfeild-userDetails'
                placeholder='Add Description'
              />
            </div>
          </div>

          <div className="row-userDetails" style={{ width: '98%' }}>
            <div className="input-feild-label">
              <p className="paragraph-details-user"> Cut Price</p>
              <input
                type='number'
                value={data.cutPrice}
                onChange={(e) => setData({ ...data, cutPrice: e.target.value })}
                className='inputfeild-userDetails'
                placeholder='Add Cut Price'
              />
            </div>

            <div className="input-feild-label">
              <p className="paragraph-details-user"> Actual Price</p>
              <input
                type='number'
                value={data.actualPrice}
                onChange={(e) => setData({ ...data, actualPrice: e.target.value })}
                className='inputfeild-userDetails'
                placeholder='Add Actual Price'
              />
            </div>
          </div>

          <div className="row-userDetails" style={{ width: '98%' }}>
            <div className="input-feild-label">
              <p className="paragraph-details-user"> Book Downloads Limits</p>
              <input
                type='number'
                value={data.bookDownload}
                onChange={(e) => setData({ ...data, bookDownload: e.target.value })}
                className='inputfeild-userDetails'
                placeholder='Book Downloads Limits'
              />
            </div>

            <div className="input-feild-label">
              <p className="paragraph-details-user"> Audio Book Downloads Limit</p>
              <input
                type='number'
                value={data.audioBookDownload}
                onChange={(e) => setData({ ...data, audioBookDownload: e.target.value })}
                className='inputfeild-userDetails'
                placeholder='Audio Book Downloads Limit'
              />
            </div>
          </div>
          <button className='button-all-css' onClick={handleSubmit} style={{ marginTop: '2em', width: '100%', textAlign: 'center' }}>
            {loading ? <CircularProgress color="inherit" size={24} /> : 'Add Payment Plan'}
          </button>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default AddPaymentPlan;
