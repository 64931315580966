
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { BookColmData } from "../../datatablesource"; // Assuming this contains your column definitions
import '../UserDetailsViewpg/UserDetails.css';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import './app.css';

const AllBannerBookTable = ({ searchQuery }) => {
  const [data, setData] = useState([]); // State to hold book data
  const [isLoading, setIsLoading] = useState(false); // Loading state

  // Function to fetch all books from the server
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('https://backend.mysecretlit.com/api/book/getBooks');
      
      // Reverse data and map _id to id
      const formattedData = response.data.data.reverse().map((item) => ({
        ...item,
        id: item._id, // Map _id to id
      }));
      
      // Sort so that books with homeBanner: true appear at the top
      const sortedData = formattedData.sort((a, b) => {
        if (a.homeBanner === b.homeBanner) return 0;
        return a.homeBanner ? -1 : 1; // Move homeBanner: true to the top
      });
      
      setData(sortedData);
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Failed to fetch books. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };
  

  useEffect(() => {
    fetchData();
  }, []);
  const handleBannerAction = async (bookId, currentBannerStatus) => {
    try {
      // Retrieve the token from localStorage
      const token = localStorage.getItem('maintoken');
      
      // Set up the headers with the token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      
      if (currentBannerStatus) {
        // If banner is already added, send a request to remove it
        const response = await axios.post('https://backend.mysecretlit.com/api/makeHomeBanner', {
          status: 'false',
          bookId: bookId
        }, config);
        
        if (response.data.success) {
          toast.success('Banner removed successfully');
          fetchData(); // Refresh data after successful deletion
        } else {
          fetchData();
        }
      } else {
        // If banner is not added, send a request to add it
        const response = await axios.post('https://backend.mysecretlit.com/api/makeHomeBanner', {
          status: 'true',
          bookId: bookId
        }, config);
        
        if (response.data.success) {
          toast.success('Banner added successfully');
          fetchData();
        } else {
          fetchData();
        }
      }
    } catch (error) {
      console.error('Error updating banner status:', error);
      toast.error('Failed to update banner status');
    }
  };
  
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        const currentBannerStatus = params.row.homeBanner;
        return (
          <div className="cellAction">
            <div
              onClick={() => handleBannerAction(params.row.id, currentBannerStatus)}
              className={`viewButton ${currentBannerStatus ? 'bannerAdded' : ''}`}
            >
              {currentBannerStatus ? 'Delete Book Banner' : 'Add Book Banner'}
            </div>
          </div>
        );
      },
    },
  ];


  return (
    <div className="datatable" style={{ paddingTop: 0 }}>
      <ToastContainer />
      {isLoading ? (
        <div className="loadingSpinner">
          <CircularProgress color="error" />
        </div>
      ) : (
        <DataGrid
          className="datagrid"
          rows={data}
          columns={BookColmData.concat(actionColumn)}
          pageSize={100}
          rowsPerPageOptions={[30]}
        />
      )}
    </div>
  );
};

export default AllBannerBookTable;
