import React, { useEffect, useState } from 'react'
import "../list/list.scss"
import '../UserDetailsViewpg/UserDetails.css'
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import {paymentPlanData, paymentPlanDataobj } from '../../datatablesource'
import { DataGrid } from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import { CircularProgress } from '@mui/material'
const PaymentsPlans = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigation = useNavigate();

  const fetchData = async () => {
    const token = localStorage.getItem('maintoken'); // Retrieve token from local storage

    setIsLoading(true);
    try {
      const response = await axios.get('https://backend.mysecretlit.com/api/getPlans',{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const formattedData = response.data.data.reverse().map((item, index) => ({
        ...item,
        id: item._id
      }));
      setData(formattedData);
      console.log(response.data. getAllPlans )
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Failed to fetch books. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    const token = localStorage.getItem('maintoken'); // Retrieve token from local storage

    try {
      const res = await axios.delete(`https://backend.mysecretlit.com/api/deletePlan/${id}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 200) {
        setData(data.filter((item) => item.id !== id));
        toast.success('Plan deleted successfully!');
      } else {
        toast.error('Failed to delete book. Please try again.');
      }
    } catch (error) {
      console.error('Error making API call:', error);
      toast.error('Failed to delete book. Please try again.');
    }
  };
  const handleViewDetails = (params) => {
    navigation('/Dashboard/payment-plans/payment-plan-details', { state: { paymentData: params } });
  };
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 120,
      renderCell: (params) => {
        return (
          <div className="cellAction">
   
              <div onClick={()=>handleViewDetails(params.row)} className="viewButton">Edit</div>
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className='list'>
    <Sidebar/>
       <div className="listContainer">
           
       <Navbar headingmain='Payment Plan' />
       <div className='all-book-style'> 
       <div className="add-categroy-name" style={{width:'100%',margin:'auto',marginTop:30,paddingRight:20}}>
            <div className="auidoBook-div-sub-coln"><h2 className='heading-h2-all-main' style={{color:'#ee2d2f',fontSize:22}}>Add New Payment Plan</h2></div>
            <div className="auidoBook-div-sub-coln"><button className="addButton" onClick={()=>navigation('/Dashboard/payment-plans/add-payment-plan')}>Add plan</button></div>
        </div>
      </div>
       {/* <div className="datatable" style={{marginRight:20,paddingTop:0}} > */}
       <div className="datatable" style={{marginRight:20,paddingTop:0}}>
      <ToastContainer/>
      {isLoading ? (
        <div className="loadingSpinner">
          <CircularProgress color="error" />
        </div>
      ) : (
      <DataGrid
        className="datagrid"
        rows={data}
        columns={paymentPlanData.concat(actionColumn)}
        pageSize={20}
        rowsPerPageOptions={[9]}
      />)}
    </div> 
       
       </div>
       </div>
  )
}

export default PaymentsPlans