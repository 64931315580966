import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CircularProgress } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment-timezone';

const PromoCodeGenerator = () => {
  const [promoCodes, setPromoCodes] = useState([]);
  const [newPromoCode, setNewPromoCode] = useState('');
  const [promoCodeDiscount, setPromoCodeDiscount] = useState('');
  const [expiridate, setExpiridate] = useState(new Date());
  const [startdate, setStartdate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  const payload = {
    name: newPromoCode,
    discount: promoCodeDiscount,
    endDate: moment(expiridate).format('YYYY-MM-DD'),
    startDate: moment(startdate).format('YYYY-MM-DD')
  };

  // Function to fetch promo codes from the API
  const fetchPromoCodes = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://backend.mysecretlit.com/api/promoCode');
      setPromoCodes(response.data?.data?.reverse());
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error('Server not responding');
      } else {
        toast.error('Unexpected error occurred');
      }
    }
  };

  // Function to handle adding a promo code to the list
  const handleAddPromoCode = async () => {
    const token = localStorage.getItem('maintoken');
    if (newPromoCode.trim() === '') {
      toast.error('Please enter a promo code.');
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post('https://backend.mysecretlit.com/api/addPromoCode', payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      toast.success('Promo code added successfully');
      setPromoCodes([...promoCodes, response.data]);
      window.location.reload()
      setNewPromoCode('');
      setStartdate(new Date());
      setExpiridate(new Date());
      setPromoCodeDiscount('');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error('Server not responding');
      } else {
        toast.error('Unexpected error occurred');
      }
    }
  };

  const formatDate = (dateString) => {
    const date = moment(dateString).tz('Asia/Karachi'); // Change to desired timezone
    return date.format('DD/MM/YYYY'); // Change format as needed
  };

  const handleDeletePromoCode = async (code) => {
    const token = localStorage.getItem('maintoken');
    setLoading(true);
    try {
      await axios.delete(`https://backend.mysecretlit.com/api/deletePromoCode/${code}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const updatedPromoCodes = promoCodes.filter((item) => item._id !== code);
      setPromoCodes(updatedPromoCodes);
      setLoading(false);
      toast.success('Promo code deleted successfully');
    } catch (error) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error('Server not responding');
      } else {
        toast.error('Unexpected error occurred');
      }
    }
  };

  useEffect(() => {
    fetchPromoCodes();
  }, []);

  return (
    <div style={{ maxWidth: '900px', margin: 'auto', marginTop: '50px' }}>
      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress color="error" />
        </div>
      )}
      <div style={{ alignItems: 'end', display: 'flex', marginBottom: '20px', gap: 10 }}>
        <div>
          <label htmlFor="">Promo Code Name</label>
          <input
             className='inputfeild-userDetails'
            type="text"
            value={newPromoCode}
            onChange={(e) => {
              const regex = /^[a-zA-Z0-9]*$/; // Only allows letters and numbers
              if (regex.test(e.target.value)) {
                setNewPromoCode(e.target.value); // Only set the value if it matches the regex
              }
            }}
            placeholder="Enter promo code name"
            style={{ flex: '1', padding: '8px', marginRight: '10px', fontSize: '14px' }}
          />
        </div>
        <div>
          <label htmlFor="">Promo Code Discount</label>
          <input
            className='inputfeild-userDetails'
            type="number"
            value={promoCodeDiscount}
            onChange={(e) => setPromoCodeDiscount(e.target.value)}
            placeholder="Enter promo code discount number"
            style={{ flex: '1', padding: '8px', marginRight: '10px', fontSize: '14px' }}
          />
        </div>
        <div>
          <label htmlFor="">Start Date</label>
          <DatePicker
            selected={startdate}
            onChange={(date) => setStartdate(date)}
            dateFormat="dd/MM/yyyy"
            className='inputfeild-userDetails'
            placeholderText="dd/mm/yyyy"
            style={{ flex: '1', padding: '8px', marginRight: '10px', fontSize: '14px' }}
          />
        </div>
        <div>
          <label htmlFor="">Expire Date</label>
          <DatePicker
            selected={expiridate}
            onChange={(date) => setExpiridate(date)}
            dateFormat="dd/MM/yyyy"
            className='inputfeild-userDetails'
            placeholderText="dd/mm/yyyy"
            style={{ flex: '1', padding: '8px', marginRight: '10px', fontSize: '14px' }}
          />
        </div>
        <button
          className='button-all-css'
          onClick={handleAddPromoCode}
          style={{ padding: '8px 20px', fontSize: '14px', height: 41 }}
        >
          Add
        </button>
      </div>
      <div>
        <h3 style={{ fontSize: '20px', marginBottom: '10px' }}>List of Promo Codes</h3>
        <ul style={{ listStyleType: 'none', padding: '0' }}>
          {promoCodes.map((promoCode, index) => (
            <li key={index} style={{ marginBottom: '10px', borderBottom: '1px solid #ddd', paddingBottom: '10px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                  <span style={{ fontWeight: 'bold', marginRight: '10px', color: 'black' }}>
                    Promo Code Name: <span style={{ color: 'rgb(238, 45, 47)' }}>{promoCode?.name}</span>
                  </span>
                  <div>
                    <span style={{ fontWeight: 'bold', marginRight: '10px', color: 'black' }}>
                      Promo Code Discount: <span style={{ color: 'rgb(238, 45, 47)' }}>{promoCode?.discount}</span>
                    </span>
                  </div>
                  <div>
                    <span style={{ fontWeight: 'bold', marginRight: '10px', color: 'black' }}>
                      Start Date: <span style={{ color: 'rgb(238, 45, 47)' }}>{formatDate(promoCode?.startDate)}</span>
                    </span>
                  </div>
                  <div>
                    <span style={{ fontWeight: 'bold', marginRight: '10px', color: 'black' }}>
                      End Date: <span style={{ color: 'rgb(238, 45, 47)' }}>{formatDate(promoCode?.endDate)}</span>
                    </span>
                  </div>
                </div>
                <div>
                  <button
                    className='button-all-css'
                    onClick={() => handleDeletePromoCode(promoCode._id)}
                    style={{ padding: '6px 12px', fontSize: '12px', color: 'white', background: '#ee2d2f' }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default PromoCodeGenerator;
