import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { BookColmData, FAQSColmData } from "../../datatablesource";

const AllFAQSQuesTable = ({ searchQuery }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigation = useNavigate();

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('https://backend.mysecretlit.com/api/getAllFaqQuestions');
      const formattedData = response.data.data.reverse().map((item, index) => ({
        ...item,
        id: item._id
      }));
      setData(formattedData);
      console.log(response.data)
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Failed to fetch books. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, []);

  const handleDelete = async (id) => {
    const token = localStorage.getItem('maintoken');
    try {
      const res = await axios.delete(`https://backend.mysecretlit.com/api/deleteFaqQuestion/${id}`,{
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (res.status === 200) {
        setData(data.filter((item) => item.id !== id));
        console.log(res.status)
        toast.success('FAQS deleted successfully!');
      } else {
        toast.error('Failed to delete book. Please try again.');
      }
    } catch (error) {
      console.error('Error making API call:', error);
      toast.error('Failed to delete book. Please try again.');
    }
  };

  const handleViewDetails = (params) => {
    navigation('/Dashboard/get-all-faqs/update-faqs-question-and-answer', { state: { dataa: params } });
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div onClick={() => handleViewDetails(params.row)} className="viewButton">Edit</div>
            <div className="deleteButton" onClick={() => handleDelete(params.row.id)}>Delete</div>
          </div>
        );
      },
    },
  ];

  const filteredData = data.filter(faq => 
    faq.question.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="datatable">
      <ToastContainer/>
      {isLoading ? (
        <div className="loadingSpinner">
          <CircularProgress color="error" />
        </div>
      ) : (
      <DataGrid
        className="datagrid"
        rows={filteredData}
        columns={FAQSColmData.concat(actionColumn)}
        pageSize={70}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
      />)}
    </div>
  );
};

export default AllFAQSQuesTable;
