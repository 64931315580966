import React, { useState, useRef } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const OTPInput = () => {
  const [otp, setOtp] = useState(["", "", "", ""]); // Holds the OTP values
  const inputRefs = useRef([]); // Refs for input fields
  const [showModal, setShowModal] = useState(false);

  // Function to generate a random 4-digit OTP and open the modal
  const generateOtp = () => {
    // Here you could generate and log an OTP if needed
    // For this example, we will just log a static OTP
    console.log("Generated OTP: 1234"); // Simulated generated OTP
    setShowModal(true);
  };

  // Handle input change
  const handleInputChange = (e, index) => {
    const { value } = e.target;

    // Allow only numeric input
    if (/^[0-9]$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Focus the next input if current is filled
      if (value && index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  // Handle key down event for backspace
  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !otp[index]) {
      // Focus the previous input if current is empty
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    const enteredOtp = otp.join("");
    console.log("Entered OTP:", enteredOtp); // Log entered OTP
    alert(`OTP Entered: ${enteredOtp}`); // Optional alert for user feedback
    setShowModal(false); // Close the modal
    setOtp(["", "", "", ""]); // Reset input fields
  };

  return (
    <div>
      <Button variant="primary" onClick={generateOtp}>
        Generate OTP
      </Button>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Enter Your OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} className="d-flex justify-content-center">
            {otp.map((value, index) => (
              <Form.Control
                key={index}
                type="text"
                value={value}
                onChange={(e) => handleInputChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                ref={el => inputRefs.current[index] = el} // Assign ref to input
                className="mx-1"
                style={{ width: "50px", textAlign: "center" }}
                maxLength="1" // Limit input to one character
              />
            ))}
            <Button variant="success" type="submit" className="ms-2">
              Submit OTP
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default OTPInput;
