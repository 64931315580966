import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { NotificationName } from "../../datatablesource";
import { useNavigate } from "react-router-dom";

const NotificationDAtaTable = ({ searchQuery }) => {
  const navigation  = useNavigate()
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // State for loading indicator
  const handleDelete = async (id) => {
    const token = localStorage.getItem('dashboard_key')
    setData(data.filter((item) => item.id !== id)); // Update local state
    try {
      await axios.delete(`https://backend.mysecretlit.com/api/deleteNotification?notifId=${id}`,{
        headers: {
          Authorization: `Bearer ${token}`
        },
      });
      toast.success('This Notification is successfully deleted!'); // Show success toast
    } catch (error) {
      console.error('Error making API call:', error);
      if (error.response) {
        console.log('Error Response Data:', error.response.data);
        toast.error(error.response.data.message || 'Error deleting category. Please try again.');
      } else if (error.request) {
        console.log('Error Request Data:', error.request);
        toast.error('No response from server. Please try again.');
      } else {
        console.log('Error Message:', error.message);
        toast.error('Error deleting category. Please try again.');
      }
    }
  };

  const handleEdit = (id) => {
    navigation('/Dashboard/all-notifications/update-notifications', { state: { dataa: id } });
   
  };
  const fetchData = async () => {
    const token = localStorage.getItem('dashboard_key')
    setIsLoading(true); // Start loading indicator
    try {
      const response = await axios.get('https://backend.mysecretlit.com/api/getAllNotification',{
        headers: {
          Authorization: `Bearer ${token}`
        },
      });
      const formattedData = response.data.data.reverse().map((item) => ({
        ...item,
        id: item._id, // Use '_id' or another unique property as 'id'
        notificationTitle: item.notification?.title || '', // Ensure 'title' is set
        notificationDescription: item.notification?.description || '', // Ensure 'description' is set
      }));
      setData(formattedData);
      // setData(response.data.data);
      console.log("asdasdnotificat",response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false); // Stop loading indicator
    }
  };

    const safeToLower = (str) => (str ? str.toLowerCase() : '');
  const filteredData = data.filter((item) =>
    safeToLower(item.notificationTitle).includes(safeToLower(searchQuery))
  );

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, []);

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 350,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            
            <button
                  className="viewButton"
                  onClick={() => handleEdit(params.row)}
                >
                  Edit
                </button>
                <button
                  className="deleteButton"
                  onClick={() => handleDelete(params.row.id)}
                >
                  Delete
                </button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="datatable" style={{ paddingTop: 0 }}>
      <ToastContainer />
      {isLoading ? (
        <div className="loadingSpinner">
          <CircularProgress color="error" />
        </div>
      ) : (
        data?.length > 0 ? (
          <DataGrid
            className="datagrid"
            rows={filteredData}
            columns={NotificationName?.concat(actionColumn)}
            pageSize={15}
            rowsPerPageOptions={[9]}
            checkboxSelection
          />
        ) : (
          <p>No data available</p> // Handle empty state if necessary
        )
      )}
    </div>
  );
};

export default NotificationDAtaTable;
