import React, { useState } from 'react'
import "../list/list.scss"
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import { useNavigate } from 'react-router-dom';
import NotificationDAtaTable from './NotiifcationDataTable';
const AllNotifications = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const navigation = useNavigate()
  return (
    <div className='list'>
        <Sidebar/>
        <div className="listContainer">
        <Navbar onchange={(e) => setSearchQuery(e.target.value)} searchQuery={searchQuery} headingmain="All Notifications" showSearchBar={true}/>
        <div className="add-categroy-name" style={{width:'97%',margin:'auto',marginTop:30,paddingRight:10}}>
            <div className="auidoBook-div-sub-coln"><h2 className='heading-h2-all-main' style={{color:'#ee2d2f',fontSize:22}}>Add New Notifications</h2></div>
            <div className="auidoBook-div-sub-coln"><button className="addButton" onClick={()=>navigation('/Dashboard/all-notifications/add-notifications')} style={{width:'13em',background:'#ee2d2f',color:'white'}}>Add New Notifications</button></div>
        </div>
        <NotificationDAtaTable searchQuery={searchQuery}/>
        <div className='all-book-style'>
        </div>
        
      </div>
    </div>
  )
}

export default AllNotifications