import React, { useEffect, useState } from 'react';
import "../list/list.scss";
import '../UserDetailsViewpg/UserDetails.css';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { Autocomplete, Avatar, Box, Rating, TextField, Typography } from '@mui/material';
import top100Films from './top100Films';

const AddBookss = () => {
  const [catdata, setcatdata] = useState([]);
  const navigate = useNavigate();
  const [seriesdata,setseriesdata] = useState([])
  const [selectedBookId, setSelectedBookId] = useState(null);
  const token = localStorage.getItem('maintoken')
  const [data, setData] = useState({
    Bookname: '',
    RedTitleBanner: '',
    authorName: '',
    bookcategoryPrimary: '',
    bookcategoriesSec: '',
    shortDescription: '',
    longDescription: '',
    image: null,
    pdfUpdate: null,
    epubUpload: null,
    kindleMobiUpload: null,
    series: 'No',
    rating: '0',
    TriggerWarning: ''
  });
  const [loading, setLoading] = useState(false);
  const [isSeriesFieldVisible, setIsSeriesFieldVisible] = useState(false);
 
  const dataseries = [
    { id: 1, name: 'Yes' },
    { id: 2, name: 'No' }
  ];

  const handleFileChange = (e, fileType) => {
    const file = e.target.files[0];
    setData({ ...data, [fileType]: file });
  };

  const fetchData = async () => {
    try {
      const response = await axios.get('https://backend.mysecretlit.com/api/user/getGenres?type=test');
      const formattedData = response.data?.data.map((item) => ({
        ...item,
        id: item._id
      }));
      setcatdata(formattedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const uploadFile = async (file, type) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post('https://backend.mysecretlit.com/api/uploadFile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return response.data.data.path;
    } catch (error) {
      console.error(`Error uploading ${type}:`, error);
      toast.error(`Error uploading ${type}. Please try again.`);
      return "";
    }
  };
 
  const handleSubmit = async () => {
    setLoading(true);
    let bookCoverImageUrl = "";
    let pdfUrl = "";
    let epubUrl = "";
    let mobiUrl = "";

    // Upload files if present
    if (data.image) bookCoverImageUrl = await uploadFile(data.image, "image");
    if (data.pdfUpdate) pdfUrl = await uploadFile(data.pdfUpdate, "PDF");
    if (data.epubUpload) epubUrl = await uploadFile(data.epubUpload, "EPUB");
    if (data.kindleMobiUpload) mobiUrl = await uploadFile(data.kindleMobiUpload, "MOBI");

    const payload = {
      title: data.Bookname,
      downloads: 0,
      type: "book",
      reads: 0,
      banner: data.RedTitleBanner,
      authorName: data.authorName,
      category:data.bookcategoryPrimary,
      primaryCategory: data.bookcategoryPrimary,
      secondaryCategory: data.bookcategoriesSec,
      series: data.series === 'Yes',
      shortDescription: data.shortDescription,
      triggerWarningText: data.TriggerWarning,
      longDescription: data.longDescription,
      rating: data.rating,
      // seriesList:[selectedBookId],
      coverImage: bookCoverImageUrl, // Set the uploaded image URL
      pdfUrl: pdfUrl, // Set the uploaded PDF URL
      epubUrl: epubUrl, // Set the uploaded EPUB URL
      kindleMobiUrl: mobiUrl // Set the uploaded MOBI URL
    };

    try {
      const res = await axios.post('https://backend.mysecretlit.com/api/book/addBook', payload,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
     
      toast.success('Your book has been successfully added!');
      setTimeout(() => {
        navigate('/all-books');
      }, 2000);
    } catch (error) {
      console.error('Error adding book:', error);
      if (error.response) {
        // Customize error message based on the response data
        if (error.response.data.error) {
          toast.error(error.response.data.error);
        } else {
          toast.error(error.response.data.error || 'Error adding book. Please try again.');
        }
      } else if (error.request) {
        toast.error('No response from server. Please try again.');
      } else {
        toast.error('Error adding book. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleSeriesChange = (e) => {
    const { value } = e.target;
    setData({ ...data, series: value });
    setIsSeriesFieldVisible(value === 'Yes');
  };
 

  const handleBookSelect = (event, newValue) => {
    if (newValue) {
      setSelectedBookId(newValue.id); // Get the selected book ID
      console.log("Selected Book ID:", newValue.id);
    }
  };
  return (
    <div className='list'>
           <Sidebar />
          <div className="listContainer">
             <Navbar headingmain="Add New Book" />
            <div className='all-book-style'>
           {loading && (
            <div className="spinner-overlay">
              <Spinner animation="border" variant="danger" />
            </div>
          )}
          {/* <div className="row-userDetails" style={{ width: '98%' }}> */}
        <div className="form-container">
          <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
          <div className="row-userDetails" style={{ width: '98%' }}>
            <div className="input-feild-label">
              <p className="paragraph-details-user">Book Title (white)</p>
              <input type="text" name="Bookname" value={data.Bookname} onChange={handleInputChange} required  className='inputfeild-userDetails'
                 placeholder='Book Title (white)' />
            </div>
            <div className="input-feild-label">
              <p className="paragraph-details-user">Book Title/Oblic author(red)</p>
              <input  placeholder='Book Title/Oblic author(red)' type="text" name="RedTitleBanner" className='inputfeild-userDetails' value={data.RedTitleBanner} onChange={handleInputChange} required />
            </div>
            </div>

            <div className="row-userDetails" style={{ marginTop: '1em', width: '98%' }}>
            <div className="input-feild-label">
              <p className="paragraph-details-user">Author Name</p>
              <input type="text" name="authorName" value={data.authorName} onChange={handleInputChange} required  className='inputfeild-userDetails'
                 placeholder='Author Name' />
            </div>
            <div className="input-feild-label">
  <p className="paragraph-details-user">Primary Category</p>
  <select
    id="cars"
    name="bookcategoryPrimary"
    value={data.bookcategoryPrimary}
    onChange={handleInputChange}
    required
  >
    <option value="" disabled>
      Select Primary Category
    </option>
    {catdata.map((category) => (
      <option key={category.id} value={category.id}>
        {category.name}
      </option>
    ))}
  </select>
</div>
            </div>

            <div className="row-userDetails" style={{ marginTop: '1em', width: '98%' }}>
            <div className="input-feild-label">
              <p className="paragraph-details-user">Secondary Category</p>
              <select
      id="cars"
      name="bookcategoriesSec"
      value={data.bookcategoriesSec}
      onChange={handleInputChange}
      required
    >
      {/* Default placeholder option */}
      <option value="" disabled>
        Select Secondary Category
      </option>
      {/* Mapping the options from catdata */}
      {catdata.map((category) => (
        <option key={category.id} value={category.id}>
          {category.name}
        </option>
      ))}
    </select>
            </div>
            <div className="input-feild-label">
              <p className="paragraph-details-user">Series</p>
              <select id="cars" name="series" value={data.series} onChange={handleSeriesChange} required>
                {dataseries.map((option) => (
                  <option key={option.id} value={option.name}>{option.name}</option>
                ))}
              </select>
            </div>
            </div>
            {/* <div className="input-feild-label" style={{ marginTop: '1em', width: '98%' }}>
           {isSeriesFieldVisible && (
              <div className="form-group">
                <p className="paragraph-details-user"> Series Name</p>
  
                <Autocomplete
      options={seriesdata}
      getOptionLabel={(option) => option.title}
      onChange={handleBookSelect}
      sx={{ width: '100%' }}
      renderInput={(params) => <TextField {...params}  />}
      renderOption={(props, option) => (
        <Box component="li" {...props} display="flex" alignItems="center">
          <Avatar
            src={option.coverImage}
            alt={option.title}
            sx={{ marginRight: 2 }}
          />
          <Box>
            <Typography variant="body1" fontWeight="bold">
              {option.title}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {option.authorName}
            </Typography>
          </Box>
        </Box>
      )}
    />
              </div>
            )}
          </div> */}
            
                      <div className="input-feild-label" style={{ marginTop: '1em', width: '98%' }}>
            <p className="paragraph-details-user"> Short Description</p>
            <textarea
            name="shortDescription" 
              value={data.shortDescription} onChange={handleInputChange} required
              rows="3"
              className='inputfeild-userDetails'
              placeholder='Short Description'
            />
          </div>
          <div className="input-feild-label" style={{ marginTop: '1em', width: '98%' }}>
            <p className="paragraph-details-user"> Long Description</p>
            <textarea
           name="longDescription" value={data.longDescription} onChange={handleInputChange} required
              rows="3"
              className='inputfeild-userDetails'
              placeholder='Long Description'
            />
          </div>
          <div className="input-feild-label" style={{ marginTop: '1em', width: '98%' }}>
            <p className="paragraph-details-user"> Trigger Warning Text</p>
            <textarea
          name="TriggerWarning" value={data.TriggerWarning} onChange={handleInputChange} 
              rows="2"
              className='inputfeild-userDetails'
              placeholder='Trigger Warning Text'
            />
          </div>

           <div className="input-feild-label" style={{ marginTop: '1em', width: '98%' }}>
             <p className="paragraph-details-user">Book Rating</p>
             <Rating
                name="rating"
                value={parseFloat(data.rating)}
                onChange={(e, newValue) => setData({ ...data, rating: newValue })}
                precision={0.5}
              />
          </div>
          <div className="input-feild-label" style={{ marginTop: '1em', width: '98%' }}>
            <p className="paragraph-details-user">Upload Book Cover Image Photo</p>
            <input  type="file" accept="image/*" onChange={(e) => handleFileChange(e, 'image')} />
            {/* {data.image && (
              <div>
                <p>{data?.image?.name}</p>
              </div>
            )} */}
                            <h3>Selected Image:</h3>

            {data.image && (
              <div>
                <img src={URL.createObjectURL(data.image)} alt="Selected" style={{ maxWidth: '100%', width: '16%' }} />
              </div>
            )}
          </div>
                  <div className="input-feild-label" style={{ marginTop: '1em', width: '98%' }}>
         <p className="paragraph-details-user">Upload PDF</p>
         <input type="file" accept=".pdf" onChange={(e) => handleFileChange(e, 'pdfUpdate')} />
             {data.pdfUpdate && (
              <div>
                <p>{data?.pdfUpdate?.name}</p>
              </div>
            )}
          </div>
          <div className="input-feild-label" style={{ marginTop: '1em', width: '98%' }}>
         <p className="paragraph-details-user">Upload EPUB</p>
         <input type="file" accept=".epub" onChange={(e) => handleFileChange(e, 'epubUpload')} />        
              {data.pdfUpdate && (
              <div>
                <p>{data?.epubUpload?.name}</p>
              </div>
            )}
          </div>
          <div className="input-feild-label" style={{ marginTop: '1em', width: '98%' }}>
         <p className="paragraph-details-user">Upload MOBI</p>
         <input type="file" accept=".mobi" onChange={(e) => handleFileChange(e, 'kindleMobiUpload')} />            {data.pdfUpdate && (
              <div>
                <p>{data?.kindleMobiUpload?.name}</p>
              </div>
            )}
          </div>
            <button className='button-all-css' type="submit" disabled={loading} style={{width:'100%',marginTop:'1em'}}>
              {loading ? <Spinner animation="border" size="sm" /> : 'Add Book'}
            </button>
          </form>
        </div>
        </div>
        {/* </div> */}
      </div>
      <ToastContainer autoClose={2000} />
    </div>
  );
};

export default AddBookss;
