import React, { useEffect, useState } from 'react'
import Navbar from '../../components/navbar/Navbar'
import Sidebar from '../../components/sidebar/Sidebar'
import { useLocation } from 'react-router-dom'
import moment from 'moment-timezone'
import axios from 'axios'
const RefundDetails = () => {
    const location = useLocation();
    const { data } = location.state;
        const originalDate = data?.userId?.createdAt;
        const formattedDate = moment.tz(originalDate, 'America/New_York').format('MMMM Do YYYY, h:mm A'); // Change the timezone as needed
        const formattedMessageSendDate = moment.tz(data?.createdAt, 'America/New_York').format('MMMM Do YYYY, h:mm A'); // Change the timezone as needed

    const [plandata,setplandata] = useState()
    console.log('plandata',data)
    
      const fetchDataplan = async () => {
           
        try {
          const response = await axios.get(`https://new-app-testing-2d30280db142.herokuapp.com/api/payments/get-all-remaning-dowloads/${data?.userId._id}`);
        setplandata(response?.data?.currentPlan)

        } catch (error) {
          
        } 
      };
    useEffect(()=>{
      fetchDataplan()
    },[])

  return (
    <div className='list'>
     <Sidebar/>
        <div className="listContainer" >
            
        <Navbar headingmain='Refund User Details' />
        <div className='all-book-style'>  
        <div style={{marginTop:'10px'}}>
          <div className="profileImg">
            <img style={{width:'10em',height:'10em',borderRadius:'50%'}} src={data?.img || 'https://images.theconversation.com/files/45159/original/rptgtpxd-1396254731.jpg?ixlib=rb-4.1.0&q=45&auto=format&w=1356&h=668&fit=crop'} alt="" srcset="" />
            <div className="userData-details">
              <div className="name-tag">
                <label  >Name:</label>
                <p>{data?.name} <span style={{textTransform:"capitalize"}}>{data.lastname}</span></p>
              </div>
              
              <div className="name-tag">
                <label  >Email:</label>
                <p>{data?.userId?.email}</p>
              </div>
              
            </div>
          </div>
        </div>
        <div className="row-userDetails">
          <div className="input-feild-label">
            <p className="paragraph-details-user">First Name</p>
            <input value={data?.userId?.firstname} className='inputfeild-userDetails' placeholder='First name'/>
            </div>
          <div className="input-feild-label">
            <p className="paragraph-details-user"> Last Name</p>
            <input value={data?.userId?.lastname} className='inputfeild-userDetails' placeholder='Last name'/>
            </div>
          
        </div>
        <div className="row-userDetails" style={{marginTop:15}}>
                        <div className="input-feild-label">
                            <p className="paragraph-details-user">Books Downloads Available</p>
                            <input value={plandata?.bookDownload ||'no buy plan'} className='inputfeild-userDetails' placeholder='First name' />
                        </div>
                        <div className="input-feild-label">
                            <p className="paragraph-details-user">AudioBooks Downloads Available</p>
                            <input value={plandata?.audioBookDownload||'no buy plan'} className='inputfeild-userDetails' placeholder='Last name' />
                        </div>
                    </div>
        <div className="row-userDetails" style={{marginTop:'1em'}}>
          <div className="input-feild-label">
            <p className="paragraph-details-user">Joining Date</p>
            <input value={formattedDate} className='inputfeild-userDetails' placeholder='Joining Date'/>
            </div>
          <div className="input-feild-label">
            <p className="paragraph-details-user"> Gender</p>
            <input value={data.userId?.gender} className='inputfeild-userDetails' placeholder='Gender'/>
            </div>
          
        </div>
        <div className="input-feild-label" style={{marginTop:'1em'}}>
            <p className="paragraph-details-user"> Name</p>
            <input value={data?.name} className='inputfeild-userDetails' placeholder='Email Address'/>
            </div>
            <div className="input-feild-label" style={{marginTop:'1em'}}>
            <p className="paragraph-details-user"> Email Address</p>
            <input value={data?.userId?.email} className='inputfeild-userDetails' placeholder='Email Address'/>
            </div>
            <div className="input-feild-label" style={{ marginTop: '1em' }}>
                        <p className="paragraph-details-user">Payment Plans</p>
                        <input value={plandata?.paymentPlan || 'no buy plan'} className='inputfeild-userDetails' placeholder='Payment Plans' />
                    </div>
            <div className="input-feild-label" style={{marginTop:'1em'}}>
            <p className="paragraph-details-user"> Subject Plan</p>
            <input value={data?.subject} className='inputfeild-userDetails' placeholder='Email Address'/>
            </div>
        <div className="input-feild-label" style={{marginTop:'1em'}}>
            <p className="paragraph-details-user"> Message send Date</p>
            <input value={formattedMessageSendDate} className='inputfeild-userDetails' placeholder='Email Address'/>
            </div>
            
            <div className="input-feild-label" style={{marginTop:'1em'}}>
            <p className="paragraph-details-user"> User Message</p>
            <textarea value={data?.message} rows="4" className='inputfeild-userDetails' placeholder='User Messages'/>
            </div>
        </div>
        
        
      </div>
    </div>
  )
}

export default RefundDetails