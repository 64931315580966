
// import "./datatable.scss";
// import { DataGrid } from "@mui/x-data-grid";
// import { userColumns } from "../../datatablesource"; // Assuming userColumns is imported correctly
// import { useNavigate } from "react-router-dom";
// import { useEffect, useState } from "react";
// import axios from "axios";
// import CircularProgress from "@mui/material/CircularProgress";
// import { ToastContainer, toast } from "react-toastify";
// import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";

// const Datatable = ({ searchQuery }) => {
//   const [data, setData] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [openDialog, setOpenDialog] = useState(false);
//   const [selectedUserId, setSelectedUserId] = useState(null);
//   const token = localStorage.getItem('maintoken')
//   const navigation = useNavigate();
//   const fetchData = async () => {
//     const token = localStorage.getItem('maintoken'); // Retrieve token from local storage
//     const apiUrl = 'https://backend.mysecretlit.com/api/getAllUsers'; // Replace with your API endpoint

//     try {
//       const response = await axios.get(apiUrl, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
  
//       // Filter out objects where isSuspended is true and then map the remaining objects
//       const formattedData = response.data?.data.reverse()
//         .filter(item => !item.isSuspended) // Exclude objects where isSuspended is true
//         .map((item) => ({
//           ...item,
//           id: item._id,
//         }));
  
//       setData(formattedData);
//       console.log("Filtered user data", formattedData);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     } finally {
//       setIsLoading(false);
//     }
//   };
  

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const handleViewDetails = (params) => {
//     navigation('/users/view-details/', { state: { data: params } });
//   };

//   const filteredData = data.filter(user =>
//     user?.firstName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
//     user?.lastName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
//     user?.email?.toLowerCase().includes(searchQuery.toLowerCase())
//   );

//   const handleSuspend = async (id) => {
//     const token = localStorage.getItem('maintoken');
//     try {
//       const res = await axios.post('https://backend.mysecretlit.com/api/suspendUser',{status:'true',userId:id},{
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       if (res.status === 200) {
//         setData(data.filter((item) => item.id !== id));
//         toast.success('User successfully suspended');
//       } else {
//         console.error('Failed to suspend user. Please try again.');
//       }
//     } catch (error) {
//       console.error('Error making API call:', error);
//     }
//   };

//   const handleRefundedPlan = async (id) => {
//     try {
//       const res = await axios.post(`https://new-app-testing-2d30280db142.herokuapp.com/api/sab-admin/refund/${id}`);
//       if (res.status === 200) {
//         toast.success('Plan successfully refunded');
//       } else {
//         console.error('Failed to refund plan. Please try again.');
//       }
//     } catch (error) {
//       console.error('Error making API call:', error);
//     }
//     console.log(id)
//   };

//   const openRefundDialog = (id) => {
//     setSelectedUserId(id);
//     setOpenDialog(true);
//   };

//   const closeRefundDialog = () => {
//     setOpenDialog(false);
//     setSelectedUserId(null);
//   };

//   const confirmRefund = () => {
//     if (selectedUserId) {
//       handleRefundedPlan(selectedUserId);
//     }
//     closeRefundDialog();
//   };

//   const actionColumn = {
//     field: "action",
//     headerName: "Action",
//     width: 330,
//     renderCell: (params) => {
//       return (
//         <div className="cellAction">
//           <div onClick={() => handleViewDetails(params.row)} className="viewButton">View</div>
//           <div className="deleteButton" onClick={() => handleSuspend(params.row.id)}>Suspended</div>
//           <div className="deleteButton" onClick={() => openRefundDialog(params.row.id)}>Plan Refunded</div>
//         </div>
//       );
//     },
//   };

//   return (
//     <div className="datatable">
//       <ToastContainer />
//       {isLoading ? (
//         <div className="loadingSpinner">
//           <CircularProgress color="error" />
//         </div>
//       ) : (
//         <DataGrid
//           className="datagrid"
//           rows={filteredData}
//           columns={[...userColumns, actionColumn]}
//           pageSize={100}
//           rowsPerPageOptions={[10, 15, 20, 170]}
//         />
//       )}

//       <Dialog
//         open={openDialog}
//         onClose={closeRefundDialog}
//         aria-labelledby="refund-dialog-title"
//         aria-describedby="refund-dialog-description"
//       >
//         <DialogTitle id="refund-dialog-title">Confirm Plan Refund</DialogTitle>
//         <DialogContent>
//           <DialogContentText id="refund-dialog-description">
//             Are you sure you want to refund this user's plan?
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={closeRefundDialog} color="primary">
//             Cancel
//           </Button>
//           <Button onClick={confirmRefund} color="secondary" autoFocus>
//             Confirm
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
// };

// export default Datatable;
import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { userColumns } from "../../datatablesource"; // Assuming userColumns is imported correctly
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";

const Datatable = ({ searchQuery }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const token = localStorage.getItem('maintoken');
  const navigation = useNavigate();

  // Fetch all users and suspended users
  const fetchData = async () => {
    const token = localStorage.getItem('maintoken');
    const apiUrl = 'https://backend.mysecretlit.com/api/getAllUsers';
    const suspendedApiUrl = 'https://backend.mysecretlit.com/api/getSuspendedUsers';

    setIsLoading(true); // Show loader while fetching data

    try {
      const [allUsersResponse, suspendedUsersResponse] = await Promise.all([
        axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
        axios.get(suspendedApiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
      ]);

      const allUsers = allUsersResponse.data?.data.reverse().map((item) => ({
        ...item,
        id: item._id,
      }));

      const suspendedUsers = suspendedUsersResponse.data?.data.reverse().map((item) => ({
        ...item,
        id: item._id,
        isSuspended: true, // Mark users as suspended
      }));

      // Merge both arrays
      const combinedData = [...allUsers, ...suspendedUsers];

      setData(combinedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false); // Hide loader after fetching data
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleViewDetails = (params) => {
    navigation('/users/view-details/', { state: { data: params } });
  };

  // Filter users based on searchQuery
  const filteredData = data.filter(user =>
    user?.firstName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    user?.lastName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    user?.email?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSuspend = async (id) => {
    const token = localStorage.getItem('maintoken');
    try {
      const res = await axios.post('https://backend.mysecretlit.com/api/suspendUser', { status: 'true', userId: id }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 200) {
        setData(data.filter((item) => item.id !== id)); // Remove user from the list after suspension
        toast.success('User successfully suspended');
      } else {
        console.error('Failed to suspend user. Please try again.');
      }
    } catch (error) {
      console.error('Error making API call:', error);
    }
  };

  const handleRefundedPlan = async (id) => {
    try {
      const res = await axios.post(`https://new-app-testing-2d30280db142.herokuapp.com/api/sab-admin/refund/${id}`);
      if (res.status === 200) {
        toast.success('Plan successfully refunded');
      } else {
        console.error('Failed to refund plan. Please try again.');
      }
    } catch (error) {
      console.error('Error making API call:', error);
    }
    console.log(id)
  };

  const openRefundDialog = (id) => {
    setSelectedUserId(id);
    setOpenDialog(true);
  };

  const closeRefundDialog = () => {
    setOpenDialog(false);
    setSelectedUserId(null);
  };

  const confirmRefund = () => {
    if (selectedUserId) {
      handleRefundedPlan(selectedUserId);
    }
    closeRefundDialog();
  };

  // Define action column with inline styles for suspended and unsuspended users
  const actionColumn = {
    field: "action",
    headerName: "Action",
    width: 330,
    renderCell: (params) => {
      const isSuspended = params.row.isSuspended; // Check if the user is suspended
      return (
        <div className="cellAction">
         <div onClick={() => handleViewDetails(params.row)} className="viewButton">View</div>
           <div className="deleteButton"  style={{backgroundColor: isSuspended ? 'red' : 'transparent',color: isSuspended ? 'white' : 'red',cursor:'default'}}> {isSuspended ? 'Suspended' : 'Suspend'}</div>

          
       <div className="deleteButton" style={{cursor:'default'}} >Plan Refunded</div>

        </div>
      );
    },
  };

  return (
    <div className="datatable">
      <ToastContainer />
      {isLoading ? (
        <div className="loadingSpinner">
          <CircularProgress color="error" />
        </div>
      ) : (
        <DataGrid
          className="datagrid"
          rows={filteredData}
          columns={[...userColumns, actionColumn]}
          pageSize={100}
          rowsPerPageOptions={[10, 15, 20, 170]}
        />
      )}

      <Dialog
        open={openDialog}
        onClose={closeRefundDialog}
        aria-labelledby="refund-dialog-title"
        aria-describedby="refund-dialog-description"
      >
        <DialogTitle id="refund-dialog-title">Confirm Plan Refund</DialogTitle>
        <DialogContent>
          <DialogContentText id="refund-dialog-description">
            Are you sure you want to refund this user's plan?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeRefundDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmRefund} color="secondary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Datatable;
