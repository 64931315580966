// import React, { useState } from 'react'
// import Sidebar from '../../components/sidebar/Sidebar'
// import Navbar from '../../components/navbar/Navbar'
// import "../list/list.scss"
// import '../UserDetailsViewpg/UserDetails.css'
// import axios from 'axios'
// import { ToastContainer, toast } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css';
// import AllSeriesCreateTable from './AllSeriesCreateTable'
// const CreateASeriesBooks = () => {
//   const [name,setname] = useState('')
//   console.log('name',name)
  
//   const [searchQuery, setSearchQuery] = useState('');

// const handleSubmit = async () => {
//   const token = localStorage.getItem('maintoken');

//   try {
//     const res = await axios.post('https://backend.mysecretlit.com/api/book/addCategory',{name:name,type:'test'},{
//       headers: {
//         Authorization: `Bearer ${token}`
//       }
//     });
//     toast.success('This category is successfully added!');
//     window.location.reload()
//     setname('');

//   } catch (error) {
//     console.error('Error making API call:', error);
//     if (error.response) {
//       console.log('Error Response Data:', error.response.data);
//       toast.error(error.response.data.message || 'Error adding category. Please try again.');
//     } else if (error.request) {
//       console.log('Error Request Data:', error.request);
//       toast.error('No response from server. Please try again.');
//     } else {
//       console.log('Error Message:', error.message);
//       toast.error('Error adding category. Please try again.');
//     }
//   } 
// };

//   return (
//     <div className='list'>
//          <Sidebar/>
//        <div className="listContainer">
           
//        <Navbar headingmain="Create a series books" onchange={(e) => setSearchQuery(e.target.value)} searchQuery={searchQuery} showSearchBar={true}/>
//        <div className='all-book-style'>
//         <p className="paragraph-details-user">Add Series Name :</p>
//         <div className="add-categroy-name">
//        <div style={{width:'65%'}} >
    
//        <input onChange={(e)=>setname(e.target.value)} value={name}  className='inputfeild-userDetails' type="text" placeholder='create a new series books' />    
//        </div>
//             <div style={{width:'30%'}}>
//             <button className='button-all-css' onClick={handleSubmit} >Create Series</button>
//             </div>
//             <ToastContainer />
//         </div>
//         </div>
//         <AllSeriesCreateTable searchQuery={searchQuery}/>
        
//        </div>
//     </div>
//   )
// }

// export default CreateASeriesBooks
import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import '../list/list.scss';
import '../UserDetailsViewpg/UserDetails.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AllSeriesCreateTable from './AllSeriesCreateTable'; // Assuming you have this component to show books
import { Autocomplete, Avatar, Box, TextField, Typography } from '@mui/material';

const CreateASeriesBooks = () => {
  const [name, setName] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [bookData, setBookData] = useState([]); // To store fetched books
  const [selectedBooks, setSelectedBooks] = useState([]); // To store selected book IDs

  const fetchBooks = async () => {
    try {
      const response = await axios.get('https://backend.mysecretlit.com/api/book/getBooks?type=book');
      const seriesBooks = response.data.data
        .filter(book => book.series) // Only books with series = true
        .reverse(); // Reverse if needed
      setBookData(seriesBooks);
    } catch (error) {
      console.error('Error fetching books:', error);
      toast.error('Failed to fetch books.');
    }
  };
  const handleBookSelect = (event, value) => {
    setSelectedBooks(value); // Value contains selected books
  };
  useEffect(() => {
    fetchBooks();
  }, []);

  const handleSubmit = async () => {
    const token = localStorage.getItem('maintoken');
    if (!name || selectedBooks.length === 0) {
      toast.error('Please provide a series name and select at least one book.');
      return;
    }

    const payload = {
      name: name,
      books: selectedBooks, // Array of selected book IDs
    };
    try {
      const res = await axios.post('https://backend.mysecretlit.com/api/admin/series', payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success('Series successfully created!');
      setName(''); // Clear name input
      setSelectedBooks([]); // Clear selected books
      window.location.reload();
    } catch (error) {
      console.error('Error creating series:', error);
      if (error.response) {
        toast.error(error.response.data.message || 'Error adding series. Please try again.');
      } else if (error.request) {
        toast.error('No response from server. Please try again.');
      } else {
        toast.error('Error adding series. Please try again.');
      }
    }
  };

  return (
    <div className='list'>
      <Sidebar />
      <div className="listContainer">
        <Navbar 
          headingmain="Create a series books"
          onchange={(e) => setSearchQuery(e.target.value)}
          searchQuery={searchQuery}
          showSearchBar={true}
        />
        <div className='all-book-style'>
          <p className="paragraph-details-user">Add Series Name :</p>
          <div className="">
            <div style={{width:'65%'}}>
              <input 
                onChange={(e) => setName(e.target.value)} 
                value={name} 
                className='inputfeild-userDetails' 
                type="text" 
                placeholder='Create a new series books' 
              />    
            </div>
            <div style={{width:'65%',marginTop:20}}>
            <Autocomplete
        multiple
        options={bookData}
        getOptionLabel={(option) => option.title} // Display book title
        value={selectedBooks} // Controlled value of selected books
        onChange={handleBookSelect} // Handle selection changes
        sx={{ width: '100%' }}
        renderInput={(params) => (
          <TextField {...params} label="Select Books for Series" variant="outlined" />
        )}
        renderOption={(props, option) => (
          <Box component="li" {...props} display="flex" alignItems="center">
            <Avatar
              src={option.coverImage}
              alt={option.title}
              sx={{ marginRight: 2 }}
            />
            <Box>
              <Typography variant="body1" fontWeight="bold">
                {option.title}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {option.authorName}
              </Typography>
            </Box>
          </Box>
        )}
      />
            </div>
            <div style={{width:'30%',marginTop:20}}>
              <button className='button-all-css' onClick={handleSubmit}>Create Series</button>
            </div>
            <ToastContainer />
          </div>

          {/* Render books to select */}
          <div>
           
            
          </div>
        </div>
        <AllSeriesCreateTable searchQuery={searchQuery} />
      </div>
    </div>
  );
};

export default CreateASeriesBooks;
