// import { DataGrid } from "@mui/x-data-grid";
// import { useEffect, useState } from "react";
// import axios from "axios";
// import CircularProgress from "@mui/material/CircularProgress";
// import { ToastContainer, toast } from "react-toastify";
// import Checkbox from "@mui/material/Checkbox";
// import { allCategory } from "../../datatablesource"; // Assuming you have imported `allCategory` from your source file
// import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";

// const AllCategoryTable = ({ searchQuery }) => {
//   const [data, setData] = useState([]);
//   const [homeCategories, setHomeCategories] = useState([]); // Initialize homeCategories
//   const [isLoading, setIsLoading] = useState(false);
//   const token = localStorage.getItem('maintoken');
//   const [editModalOpen, setEditModalOpen] = useState(false);
//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const [newCategoryName, setNewCategoryName] = useState("");
//   const handleDelete = async (id) => {
//     setData(data.filter((item) => item.id !== id)); // Update local state
//     console.log('delete id', id);
//     try {
//       await axios.delete(`https://backend.mysecretlit.com/api/book/deletecategory/${id}`,{
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       toast.success('This category is successfully deleted!');
//     } catch (error) {
//       console.error('Error making API call:', error);
//       if (error.response) {
//         toast.error(error.response.data.message || 'Error deleting category. Please try again.');
//       } else if (error.request) {
//         toast.error('No response from server. Please try again.');
//       } else {
//         toast.error('Error deleting category. Please try again.');
//       }
//     }
//   };

//   const handleCategorySelect = async (id, status) => {
//     try {
//       await axios.patch(`https://backend.mysecretlit.com/api/book/selectCategory/${id}`, { status },{
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       // Update homeCategories state after patch
//       const updatedHomeCategories = homeCategories.map((cat) =>
//         cat.id === id ? { ...cat, status } : cat
//       );
//       setHomeCategories(updatedHomeCategories);
//       window.location.reload()
//     } catch (error) {
//       console.error('Error updating category status:', error);
//     }
//   };
//     const handleError = (error, defaultMessage) => {
//     if (error.response) {
//       toast.error(error.response.data.message || defaultMessage);
//     } else if (error.request) {
//       toast.error("No response from server.");
//     } else {
//       toast.error(defaultMessage);
//     }
//   };
//   const handleUpdateCategory = async () => {
//     try {
//       await axios.patch(
//         `https://backend.mysecretlit.com/api/book/updateCategory/${selectedCategory.id}`,
//         { name: newCategoryName,
//           type: "test",
//          },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       toast.success("Category updated successfully!");
//       fetchData();
//       setEditModalOpen(false);
//     } catch (error) {
//       handleError(error, "Error updating category.");
//     }
//   };
//   const fetchHomecateg = async () => {
//     setIsLoading(true);
//     try {
//       const response = await axios.get('https://backend.mysecretlit.com/api/user/getHomeGenre');
//       // Ensure response.data is an array
//       if (Array.isArray(response.data.data)) {
//         setHomeCategories(response.data.data);
//       } else {
//         console.error('Unexpected data format:', response.data);
//       }
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const fetchData = async () => {
//     setIsLoading(true);
//     try {
//       const response = await axios.get('https://backend.mysecretlit.com/api/user/getGenres?type=test');
//       const formattedData = response.data.data.reverse().map((item) => ({
//         ...item,
//         id: item._id,
//       }));
//       setData(formattedData);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const filteredData = data.filter((item) =>
//     item.name.toLowerCase().includes(searchQuery.toLowerCase())
//   );

//   useEffect(() => {
//     fetchData();
//     fetchHomecateg();
//   }, []);

//   const renderCell = (params) => {
//     if (Array.isArray(homeCategories)) {
//       const isChecked = homeCategories.some((category) => category.id === params.row.id);
//       return (
//         <Checkbox
//           checked={isChecked}
//           onChange={() => handleCategorySelect(params.row.id, !isChecked)}
//         />
//       );
//     } else {
//       console.error('homeCategories is not an array:', homeCategories);
//       return null;
//     }
//   };
//   const handleEdit = (category) => {
//     setSelectedCategory(category);
//     setNewCategoryName(category.name);
//     setEditModalOpen(true);
//   };
//   const actionColumn = [
//     // {
//     //   field: "action",
//     //   headerName: "Action",
//     //   width: 150,
//     //   renderCell: (params) => (
//     //     <div className="cellAction">
//     //       <div
//     //         className="deleteButton"
//     //         onClick={() => handleDelete(params.row.id)}
//     //       >
//     //         Delete
//     //       </div>
//     //     </div>
//     //   ),
//     // },
//     //   const actionColumn = [
//     {
//       field: "action",
//       headerName: "Action",
//       width: 250,
//       renderCell: (params) => (
//         <div className="cellAction">
//           <div className="deleteButton" onClick={() => handleDelete(params.row.id)}>
//             Delete
//           </div>
//           <div className="deleteButton"  onClick={() => handleEdit(params.row)}>
//           Edit
//           </div>
//         </div>
//       ),
//     },
//     {
//       field: "select",
//       headerName: "Home Genres Category",
//       width: 200,
//       renderCell: renderCell, // Use the renderCell function for the checkbox
//     },
//   ];

//   return (
//     <div className="datatable" style={{ paddingTop: 0 }}>
//       <div className="datatableTitle">All Categories Data</div>
//       <ToastContainer />
//       {isLoading ? (
//         <div className="loadingSpinner">
//           <CircularProgress color="error" />
//         </div>
//       ) : (
//         data?.length > 0 ? (
//           <DataGrid
//             className="datagrid"
//             rows={filteredData}
//             columns={allCategory?.concat(actionColumn)}
//             pageSize={25}
//             rowsPerPageOptions={[9]}
//             // checkboxSelection
//           />
//         ) : (
//           <p>No data available</p>
//         )
//       )}
//             <Dialog
//         open={editModalOpen} onClose={() => setEditModalOpen(false)}
//         aria-labelledby="refund-dialog-title"
//         aria-describedby="refund-dialog-description"
//       >
//         <DialogTitle id="refund-dialog-title">Edit Your Category Name</DialogTitle>
//         <DialogContent>
//         <TextField
//             label="Category Name"
//             value={newCategoryName}
//             onChange={(e) => setNewCategoryName(e.target.value)}
//             fullWidth
//             variant="outlined"
//             margin="normal"
//           />
//         </DialogContent>
//         <DialogActions>
//         <Button
//             variant="contained"
//             color="primary"
//             onClick={handleUpdateCategory}
//           >
//             Update
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
// };

// export default AllCategoryTable;
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import Checkbox from "@mui/material/Checkbox";
import { allCategory } from "../../datatablesource"; // Assuming you have imported `allCategory` from your source file
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, MenuItem, Select, InputLabel, FormControl } from "@mui/material";

const AllCategoryTable = ({ searchQuery }) => {
  const [data, setData] = useState([]);
  const [homeCategories, setHomeCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem('maintoken');
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [replacementCategory, setReplacementCategory] = useState("");

  const handleDeleteOpen = (category) => {
    setCategoryToDelete(category);
    setDeleteModalOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteModalOpen(false);
    setReplacementCategory("");
  };

  const handleDelete = async () => {
    if (!replacementCategory) {
      toast.error("Please select a replacement category.");
      return;
    }

    const payload = {
    
      newCategory: replacementCategory
    };

    try {
      await axios.delete(`https://backend.mysecretlit.com/api/book/deletecategory/${categoryToDelete.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: payload,
      });

      setData(data.filter((item) => item.id !== categoryToDelete.id));
      toast.success('Category successfully deleted and replaced!');
      handleDeleteClose();
    } catch (error) {
      console.error('Error making API call:', error);
      if (error.response) {
        toast.error(error.response.data.message || 'Error deleting category. Please try again.');
      } else if (error.request) {
        toast.error('No response from server. Please try again.');
      } else {
        toast.error('Error deleting category. Please try again.');
      }
    }
  };

  const handleCategorySelect = async (id, status) => {
    try {
      await axios.patch(`https://backend.mysecretlit.com/api/book/selectCategory/${id}`, { status }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const updatedHomeCategories = homeCategories.map((cat) =>
        cat.id === id ? { ...cat, status } : cat
      );
      setHomeCategories(updatedHomeCategories);
      window.location.reload();
    } catch (error) {
      console.error('Error updating category status:', error);
    }
  };

  const handleUpdateCategory = async () => {
    try {
      await axios.patch(
        `https://backend.mysecretlit.com/api/book/updateCategory/${selectedCategory.id}`,
        { name: newCategoryName, type: "test" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Category updated successfully!");
      fetchData();
      setEditModalOpen(false);
    } catch (error) {
      console.error('Error updating category:', error);
    }
  };

  const fetchHomecateg = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('https://backend.mysecretlit.com/api/user/getHomeGenre');
      if (Array.isArray(response.data.data)) {
        setHomeCategories(response.data.data);
      } else {
        console.error('Unexpected data format:', response.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('https://backend.mysecretlit.com/api/user/getGenres?type=test');
      const formattedData = response.data.data.reverse().map((item) => ({
        ...item,
        id: item._id,
      }));
      setData(formattedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const filteredData = data.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    fetchData();
    fetchHomecateg();
  }, []);

  const renderCell = (params) => {
    if (Array.isArray(homeCategories)) {
      const isChecked = homeCategories.some((category) => category.id === params.row.id);
      return (
        <Checkbox
          checked={isChecked}
          onChange={() => handleCategorySelect(params.row.id, !isChecked)}
        />
      );
    } else {
      console.error('homeCategories is not an array:', homeCategories);
      return null;
    }
  };

  const handleEdit = (category) => {
    setSelectedCategory(category);
    setNewCategoryName(category.name);
    setEditModalOpen(true);
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 250,
      renderCell: (params) => (
        <div className="cellAction">
          <div className="deleteButton" onClick={() => handleDeleteOpen(params.row)}>
            Delete
          </div>
          <div className="viewButton" onClick={() => handleEdit(params.row)}>
            Edit
          </div>
        </div>
      ),
    },
    {
      field: "select",
      headerName: "Home Genres Category",
      width: 200,
      renderCell: renderCell,
    },
  ];

  return (
    <div className="datatable" style={{ paddingTop: 0 }}>
      <div className="datatableTitle">All Categories Data</div>
      <ToastContainer />
      {isLoading ? (
        <div className="loadingSpinner">
          <CircularProgress color="error" />
        </div>
      ) : (
        data?.length > 0 ? (
          <DataGrid
            className="datagrid"
            rows={filteredData}
            columns={allCategory?.concat(actionColumn)}
            pageSize={25}
            rowsPerPageOptions={[9]}
          />
        ) : (
          <p>No data available</p>
        )
      )}
      
      {/* Edit Modal */}
      <Dialog
        open={editModalOpen} onClose={() => setEditModalOpen(false)}
        aria-labelledby="edit-dialog-title"
        aria-describedby="edit-dialog-description"
      >
        <DialogTitle id="edit-dialog-title">Edit Your Category Name</DialogTitle>
        <DialogContent>
          <TextField
            label="Category Name"
            value={newCategoryName}
            onChange={(e) => setNewCategoryName(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdateCategory}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteModalOpen} onClose={handleDeleteClose}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Delete Category</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this category?</p>
          <FormControl fullWidth>
            <InputLabel id="replacement-category-label">Select Replacement Category</InputLabel>
            <Select
              labelId="replacement-category-label"
              value={replacementCategory}
              onChange={(e) => setReplacementCategory(e.target.value)}
            >
              {data?.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Confirm Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AllCategoryTable;
