
import React, { useState } from 'react';
import "../list/list.scss";
import '../UserDetailsViewpg/UserDetails.css';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddPaymentPlanDetails = () => {
  const location = useLocation();
  const { paymentData } = location.state;
  const navigation = useNavigate()
console.log('paymentData',paymentData)
  const [data, setData] = useState({
    description: paymentData?.description ,
    cutPrice: paymentData?.cutPrice,
    pricing: paymentData?.pricing || paymentData?.price,
    paymentPlan: paymentData?.name,
    bookDownload:paymentData?.bookLimit,
    audioBookDownload:paymentData?.audioBookLimit
  });

  const [loading, setLoading] = useState(false);

  // const handleSubmit = async () => {
  //   const token = localStorage.getItem('maintoken'); // Retrieve token from local storage
  //   setLoading(true);
  //   try {
  //     const response = await axios.post(`https://backend.mysecretlit.com/api/updateplan/${paymentData.id}`, data);
      
  //     toast.success('Payment plan updated successfully');
  //     setTimeout(()=>{
  //       navigation('/Dashboard/payment-plans')
  //     },[1500])
  //     // Navigate to another page upon successful update
  //     // Replace 'navigateToPage' with your actual navigation logic
  //     // Example: navigate('/dashboard');
  //   } catch (error) {
  //     console.error('Error updating payment plan:', error);
  //     toast.error('Failed to update payment plan. Please try again.');
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const handleSubmit = async () => {
    const token = localStorage.getItem('maintoken'); // Retrieve token from local storage
    setLoading(true);
   
    const updatedData = {
      name: data.paymentPlan,
      description: data.description,
      duration: data.paymentPlan,  // Assuming the payment plan is the duration
      cutPrice: data.cutPrice,  // Adding $ to cutPrice if needed
      priceInNumbers: data.pricing,  // Converting pricing to number
      currency: '$',
      price: data.pricing,
      bookLimit: parseInt(data.bookDownload, 10),  // Ensure it is a number
      audioBookLimit: parseInt(data.audioBookDownload, 10),  // Ensure it is a number
      durationInDays: data.paymentPlan === '1 Month' ? 30 : data.paymentPlan === '3 Month' ? 90 : data.paymentPlan === '6 Month' ? 180 : 365
    };
   
    try {
      const response = await axios.post(`https://backend.mysecretlit.com/api/updateplan/${paymentData.id}`, updatedData,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('response',response)
      toast.success('Payment plan updated successfully');
      setTimeout(() => {
        navigation('/Dashboard/payment-plans')
      }, 1500);
    } catch (error) {
      console.error('Error updating payment plan:', error);
      toast.error('Failed to update payment plan. Please try again.');
    } finally {
      setLoading(false);
    }
   };
   

  const handlepaymentPlanChange = (e) => {
    setData({ ...data, paymentPlan: e.target.value });
  };

  const paymentPlanOptions = [
    { id: 1, paymentPlan: '1 Month free (with promo code)' },
    { id: 2, paymentPlan: '1 Month' },
    { id: 3, paymentPlan: '3 Month' },
    { id: 4, paymentPlan: '6 Month' },
    { id: 5, paymentPlan: '1 Year' }
  ];

  return (
    <div className='list'>
      <Sidebar />
      <div className="listContainer">
        <Navbar headingmain='Update Payment Plan' />
        <div className='all-book-style'>
          <div className="row-userDetails" style={{ width: '98%' }}>
            <div className="input-feild-label">
              <p className="paragraph-details-user"> Select a plan</p>
              <select id="cars"
                value={data.paymentPlan}
                onChange={handlepaymentPlanChange}
                style={{ textTransform: 'capitalize' }}
              >
                <option value=''>Select a payment plan</option>
                {paymentPlanOptions.map(option => (
                  <option key={option.id} value={option.paymentPlan}>{option.paymentPlan}</option>
                ))}
              </select>
            </div>

            <div className="input-feild-label">
              <p className="paragraph-details-user"> Description</p>
              <input
                value={data.description}
                onChange={(e) => setData({ ...data, description: e.target.value })}
                className='inputfeild-userDetails'
                placeholder='Add Description'
              />
            </div>

          </div>
          <div className="row-userDetails" style={{ width: '98%' }}>
            <div className="input-feild-label">
              <p className="paragraph-details-user"> Cut Price</p>
              <input
                value={data.cutPrice}
                onChange={(e) => setData({ ...data, cutPrice: e.target.value })}
                className='inputfeild-userDetails'
                placeholder='Add Cut Price'
              />
            </div>

            <div className="input-feild-label">
              <p className="paragraph-details-user"> Actual Price</p>
              <input
                value={data.pricing}
                onChange={(e) => setData({ ...data, pricing: e.target.value })}
                className='inputfeild-userDetails'
                placeholder='Add Actual Price'
              />
            </div>

          </div>
          <div className="row-userDetails" style={{ width: '98%' }}>
            <div className="input-feild-label">
              <p className="paragraph-details-user"> Book Downloods Limits</p>
              <input
                type='number'
                value={data.bookDownload}
                onChange={(e) => setData({ ...data, bookDownload: e.target.value })}
                className='inputfeild-userDetails'
                placeholder='Book Downloods Limits'
              />
            </div>

            <div className="input-feild-label">
              <p className="paragraph-details-user"> Audio Book Downloods Limit</p>
              <input
              type='number'
                value={data.audioBookDownload}
                onChange={(e) => setData({ ...data, audioBookDownload: e.target.value })}
                className='inputfeild-userDetails'
                placeholder='Audio Book Downloods Limit'
              />
            </div>

          </div>
          <button className='button-all-css' onClick={handleSubmit} style={{ marginTop: '2em', width: '100%', textAlign: 'center' }}>
            {loading ? <CircularProgress color="inherit" size={24} /> : 'Update Payment Plan'}
          </button>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default AddPaymentPlanDetails;
